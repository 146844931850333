import React from 'react'
import { Grid, Button } from '@material-ui/core'

import { Text } from './ui-kit/Typo'
import { Papir } from './ui-kit/Papir'
import { Spacer } from './ui-kit/Spacer'

export default function Components(props){

  return(
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Text type="subtitle">section 1. Typo</Text>
          <Text type="title" className="sb-red1">title. Heading</Text>
          <Text type="subtitle" className="sb-yellow">subtitle. Heading</Text>
          <Text type="title2" className="sb-blue1">title2. Heading</Text>
          <Text type="subtitle2" className="sb-green1">subtitle2. Heading</Text>
          <Text type="title3" className="sb-red2">title3. Heading</Text>
          <Text type="subtitle3" className="sb-red4">subtitle3. Heading</Text>
          <Text type="body" className="sb-green2">body. Big paragraph </Text>
          <Text type="p">p. Normal paragraph</Text>
        </Grid>

        <Spacer />

        <Grid item xs={12}>
          <Text type="subtitle">section 2. Colors</Text>
          {[1, 2, 3, 4].map((c, index) =>
          <Text type="subtitle" component="span" gutterBottom className={`sb-red${index+1}`}>
            {`.     sb-red${index+1}     .`}
          </Text>)}
          <Text type="title3" component="p" gutterBottom> </Text>
          {[1, 2, 3, 4].map((c, index) =>
          <Text type="subtitle" component="span" gutterBottom className={`sb-blue${index+1}`}>
            {`.     sb-blue${index+1}     .`}
          </Text>)}
          <Text type="title3" component="p" gutterBottom> </Text>
          {[1, 2, 3].map((c, index) =>
          <Text type="subtitle" component="span" gutterBottom className={`sb-green${index+1}`}>
            {`.     sb-green${index+1}     .`}
          </Text>)}
          <Text type="title3" component="p" gutterBottom> </Text>
          <Text type="subtitle" component="span" gutterBottom className={`sb-yellow`}>
            {`.     sb-yellow     .`}
          </Text>
          <Text type="subtitle" component="span" gutterBottom className={`sb-purple`}>
          {`.     sb-purple     .`}
          </Text>
          <Text type="subtitle" component="span" gutterBottom className={`sb-gray`}>
            {`.     sb-gray     .`}
          </Text>
        </Grid>

        <Spacer />

        <Grid item xs={12}>
          <Text type="subtitle">section 3. Papir</Text>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <Papir className="blue">
                <Text type="body" className="sb-purple">blue</Text>
              </Papir>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Papir className="yellow">
                <Text type="body" className="sb-red1">yellow</Text>
              </Papir>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Papir className="red">
                <Text type="body" className="sb-yellow">red</Text>
              </Papir>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Papir className="green">
                <Text type="body" className="sb-blue1">green</Text>
              </Papir>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Text type="subtitle">section 4. Buttons</Text>
          <Grid container spacing={3}>
            <Grid item>
              <Button variant="outlined" color="secondary" >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" >
                Next >
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" className="sb-gray"
                  disabled={true}>
                Disabled
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
  )

}
