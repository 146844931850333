import React, {useEffect, useState, forwardRef} from 'react';
import {convertFromRaw} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html';
import moment from 'moment';

import './style.scss';

const Content = forwardRef((props, ref) => {

    let {request, term} = props;

    const [content, setContent] = useState(stateToHTML(convertFromRaw(JSON.parse(term))))
    const replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    useEffect(() => {
        let replace = content;
        replace = replaceAll(replace, '\\[clientName\\]', request ? request.title : '');
        replace = replaceAll(replace, '\\[clientPhone\\]', request ? request.phone : '***');
        replace = replaceAll(replace, '\\[clientDetails\\]', request ? request.info : '***');
        replace = replaceAll(replace, '\\[clientAttendees\\]', request ? request.attendees : '***');
        replace = replaceAll(replace, '\\[packageName\\]', request && request.pakage ? request.pakage.name : '***');
        replace = replaceAll(replace, '\\[date\\]', moment().format("DD.MM.YYYY HH:mm"));
        // content = staff ? replaceAll(content, '\\[staffName\\]', staff.name) : content;
        setContent(replace);
    }, [term]);

    return (
        <div id={'pdf-id'} ref={ref} className={'pdf-content'} dangerouslySetInnerHTML={{__html: content}}>
        </div>
    )
});

export default Content;

