import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import { Text } from '../../components/ui-kit/Typo'

import { Helmet } from 'react-helmet';

import endOfLine from '../../assets/img/debarcare.jpeg'

export default function ComingSoonPage(props){

  let { lang } = useParams();

  return (
    <Grid container spacing={3} justify="flex-start" >

      <Helmet>
        <title>Coming soon</title>
        <meta name="description" content="Coming soon" />
        <meta name="og:image" content={endOfLine} />
        <meta name="og:image_secure" content={endOfLine} />
      </Helmet>

      <Grid item xs={6}>
        <Text type="subtitle" className={'sb-blue2'}>Still</Text>
        <Text type="subtitle" className={'sb-yellow'}>Shaping</Text>
        <Text type="subtitle" className={'sb-red1'}>.page</Text>
      </Grid>
      <Grid item xs={6} md={6}>
        <NavLink to={location => `/${lang}`}>
          <img src={endOfLine} alt="End of line" style={{maxWidth: '100%', borderRadius: '15px'}} />
        </NavLink>
      </Grid>
      <Grid item xs={6}>
        <NavLink to={location => `/${lang}`}>
          <Text type="subtitle3">{`< Imbarcare`}</Text>
        </NavLink>
      </Grid>
    </Grid>
  )
}
