import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { useParams } from "react-router-dom";

import PackageAuth from './PackageAuth';
import PackageLoading from './PackageLoading';
import PackageDetails from './PackageDetails';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PackagePage(props){

  let query = useQuery();
  const { search } = useLocation();
  const ref = useRef(null);

  const [place, setPlace] = useState();
  const [pakage, setPakage] = useState({});
  const [eventPackage, setEventPackage] = useState(undefined);
  const [getError, setGetError] = useState(false);
  const [loading, setLoading] = useState(false);

  // params when client wanna see pkg details
  let phone = query.get('t')
  let id = query.get('i')

  // params when returned from bt pay page
  let orderId = query.get('orderId');
  let token = getBtToken(window.location.search);


  useEffect(() => {
    if (ref.current)
      ref.current.scrollIntoView({behavior: 'smooth'})

    if ((phone && id) || (orderId && token))
      getPackage({phone: phone, id: id, orderId: orderId, token: token});
  }, []);

  const getPackage = (details) => {
    setLoading(true);
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + '/place/default/package/details', details)
    .then(res => {
      setEventPackage(res.data);
      setLoading(false);
    },
    err => {
      setGetError(true);
      setLoading(false);
    });
  }

  function getBtToken(queryString){
    console.log(queryString)
    const pairs = queryString.split('&');
    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        if (key == 'token')
          return value
    }
    return null}

  return (
    <div ref={ref}>
      {loading ?
        <PackageLoading />
        : eventPackage ?
        <PackageDetails eventPackage={eventPackage} orderId={orderId} token={token} phone={phone}/> :
        <PackageAuth getPackage={getPackage} getError={getError}/>}
    </div>
  )
}
