import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid';
import { Text } from '../../components/ui-kit/Typo'
import { Papir } from '../../components/ui-kit/Papir'
import Button from '@material-ui/core/Button';

import { Helmet } from 'react-helmet';

import cover from './assets/getabook/front-cover.png'
import bear from './assets/getabook/page-3.png'
import cow from './assets/getabook/page-4.png'
import fox from './assets/getabook/page-7.png'
import monkey from './assets/getabook/page-14.png'
import maze from './assets/getabook/page-31.png'

export default function GetABookPage(props){

  let [ image, setImage ] = useState(0);
  let images = [cover, bear, cow, fox, monkey, maze];

  return (
    <Grid container spacing={3} justify="flex-start" >

      <Helmet>
        <title>Happy Animals Alphabet colouring and activities book</title>
        <meta name="description" content="Kids will be able to colour, write the name of each animal, go through some maze games, connect the dots, word search and a matching game." />
        <meta name="og:image" content={cover} />
        <meta name="og:image_secure" content={cover} />
      </Helmet>

      <Grid item xs={12}>
        <Text type="subtitle" className={'sb-blue2'}>Happy</Text>
        <Text type="subtitle" className={'sb-yellow'}>Animals</Text>
        <Text type="subtitle" className={'sb-red1'}>.Alphabet</Text>
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={images[image]} alt="Happy animals alphabet"
              style={{maxWidth: '100%', borderRadius: '15px', cursor: 'pointer'}}
              onClick={() => image < 5 ? setImage(image+1) : setImage(0)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={3} justify="flex-start" >
          <Grid item xs={12}>
            <Papir className="blue">
              <Text className={'sb-purple'} semibold type="subtitle2">Buy 2 Get 1!</Text>
              <Text className={'sb-purple'} semibold type="body">We send one to you and one to a not so lucky child as you :)</Text>
              <a href="mailto:hello@snowbuddy.app?subject=Hello, vreau cartea Happy Animals Alphabet!&body=Salut, as dori o carte de colorat :) %0d%0a%0d%0aMa numesc ... si as dori ca livrarea sa se faca la adresa ... %0d%0aNumarul de telefon: %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!">
                <Button variant="outlined" className={'sb-purple'} style={{border: '1px solid #D76E91'}}>
                  Buy Now!
                </Button>
              </a>
            </Papir>
          </Grid>
          <Grid item xs={12}>
            <Papir>
              <Text type="subtitle3" className={'sb-blue2'}>About</Text>
              <Text type="body">Happy Animals Alphabet is a colouring and activities book, a fun way of learning the alphabet and some curious facts about the animals. Kids will be able to colour, write the name of each animal, go through some maze games, connect the dots, word search and a matching game. Each sheet has a single drawing and they can use markers without worrying about the other page being ruined. A great gift for any kid and a smart way of spending time, enjoy it !
              <br/>It will cost you 2x35lei + shipping</Text>
              <Text type="subtitle3" className={'sb-blue2'}>L'artiste</Text>
              <Text type="body">Dessiné avec beaucoup d'imagination par le monsieur <a href="https://www.instagram.com/danborota/" target="_blank">Dan Borota</a></Text>
            </Papir>
          </Grid>
          <Text type="subtitle3" className={'sb-yellow'}>#supportcreativity</Text>
        </Grid>
      </Grid>
    </Grid>
  )
}
