import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import axios from 'axios';

import Content from '../../event/book/terms/Content';
import Header from '../../event/book/terms/Header';

import {useParams, useLocation, useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next'

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ClientPackageTermsPage = (props) => {

    let query = useQuery();
    const history = useHistory();
    const {t} = useTranslation('iframe');

    let {lang, placeLink, cpid} = useParams();
    const [place, setPlace] = useState();
    const [error, setError] = useState({});
    const [accept, setAccept] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const pdfContentRef = useRef(null);

    // params to redirect client back
    const phone = query.get('t')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BUDDY_SERVICE}/place/${placeLink}`)
            .then(res => {
                console.log("Răspuns API pentru placeLink:", placeLink, res.data);
                setPlace(res.data);
            })
            .catch(err => {
                console.error("Eroare API pentru placeLink:", placeLink, err);
                setError({placeNotFound: true});
            });
    }, [placeLink]);


    useEffect(() => {
        if (place?.placeInfo?.localTerms) {
            try {
                const checkHeight = () => {
                    if (pdfContentRef.current) {
                        const height = pdfContentRef.current.offsetHeight + 500;
                        window.parent.postMessage({type: "setHeight", height}, "*");
                    }
                };

                // observăm modificările DOM
                const observer = new MutationObserver(() => {
                    checkHeight();
                });

                if (pdfContentRef.current) {
                    checkHeight();
                    observer.observe(pdfContentRef.current, {
                        attributes: true,
                        characterData: true,
                        childList: true,
                        subtree: true,
                    });
                }

                return () => observer.disconnect();
            } catch (err) {
                console.error("Eroare la parsarea localTerms:", err);
            }
        }
    }, [place]);

    const handleAccept = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink + '/package/terms/' + cpid)
            .then(res => {
                    setAccept(res.data);
                    setLoading(false);
                    if (res.data == false)
                        setError({termsErr: true});
                    else if (phone != null) {
                        setRedirect(true);
                        setTimeout(() => {
                            // redirect to client package if have phone param
                            history.push(`/${lang}/iframe/${placeLink}/package?i=${cpid}&t=${phone}`);
                        }, 1000);
                    }
                },
                err => {
                    setError({termsErr: true});
                    setLoading(false);
                });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {place && <Header place={place}/>}
            </Grid>
            <Grid item xs={12}>
                {place && <Content
                    term={place.placeInfo.localTerms}
                    request={null}
                    ref={pdfContentRef}
                />}
            </Grid>
            <Grid item xs={12} className="text-center">
                {!accept && <Button variant="outlined" color="secondary" onClick={handleAccept} disabled={loading}>
                    {t('client.terms.accept_btn')}
                </Button>}
                {accept && <div><DoneIcon className="text-error"/> {t('client.terms.accept')}</div>}
                {error.termsErr && <div>{t('client.terms.error')}</div>}
            </Grid>
            <Grid item xs={12} className="text-center">
            </Grid>
            <Grid item xs={12} className="text-center">
            </Grid>
        </Grid>
    );
};

export default ClientPackageTermsPage
