import React from 'react';
import snowflake from '../../assets/img/snowflake.png';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    footer: {
      color: theme.palette.text.secondary,
      paddingTop: '20px'
    }
  }));

export default function Copyright() {
  const classes = useStyles();

  return (
      <div>
            <p className={`margin-0 ${classes.footer}`}>© {new Date().getFullYear()} SnowBuddy app | <a href="https://snowbuddy.app/en/terms" className="privacy" target="_blanc">Termeni si conditii</a> | Powered with <img src={snowflake} alt="snowflake" className="me-heart" width="17;"/> by
                <a href="https://iservit.ro" className="privacy"> iServit</a>
            </p>
          <br/>
      </div>
          );
}
