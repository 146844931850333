import React from 'react'
import EventItem from './EventItem'
import { Grid, Divider } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

export default function EventsList(props) {

  let {events, resources, cancelEvent} = props

  const { t } = useTranslation('iframe');

  return (
    <div>
      <Grid container justify="center" alignItems="center" spacing={1} className="gridContainer">
        <Grid item xs={6} md={3}>
          {t('event.edit.events.event_list.name')}
        </Grid>
        <Grid item xs={6} md={2}>
          {t('event.edit.events.event_list.date')}
        </Grid>
        <Grid item xs={4} md={3}>
          {t('event.edit.events.event_list.package')}
        </Grid>
        <Grid item xs={4} md={2}>
          {t('event.edit.events.event_list.instructor')}
        </Grid>
        <Grid item xs={4} md={2} className={'text-right'}>
          {t('event.edit.events.event_list.action')}
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {events ?
        <Grid item xs={12}>
          {events.map((e, i) => {
            return (
              <EventItem
                  event={e}
                  resources={resources}
                  cancelEvent={cancelEvent}
              />)}
          )}
        </Grid> : ''}
    </div>
  )
}
