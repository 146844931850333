import React, {useState} from 'react'
import {Grid, Button} from '@material-ui/core'
import {Text} from '../../../components/ui-kit/Typo'
import {makeStyles} from '@material-ui/core/styles'
import EventBookPage from './EventBookPage'
import JsonSchema from '../../../components/general/JsonSchema'
import {useParams} from 'react-router-dom'

import {useTranslation} from 'react-i18next'
import Seo from "../../../components/general/Seo";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    },
    btn: {
        margin: 'auto 0',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        },
    }
}));

export default function PkgSimple({place}) {
    let {lang} = useParams();
    const {t} = useTranslation('listing');
    const classes = useStyles();
    const [selectedPackage, setSelectedPackage] = useState(false);

    return (
        <Grid container spacing={1}>
            <JsonSchema packages={place.placePakage.filter((obj) => {
                return obj.pkgType === 'SIMPLE';
            })} place={place} lang={lang}/>
            <Grid item xs={12}>
                <Text type="subtitle2" className="sb-red1" bold>
                    <span onClick={() => setSelectedPackage(false)} style={{cursor: 'pointer'}}>{t('SIMPLE')}</span>
                </Text>
            </Grid>
            <Grid item xs={12}>
                {selectedPackage ?
                    <EventBookPage pakage={selectedPackage} place={place} setSelectedPackage={setSelectedPackage}/> :
                    <Grid container spacing={3}>
                        {place.placePakage.filter((obj) => {
                            return obj.pkgType === 'SIMPLE';
                            // return true;
                        }).map((pkg, key) => {
                            if (!pkg.active)
                                return (null);
                            return (
                                <Grid item xs={12} onClick={() => setSelectedPackage(pkg)}>
                                    <Grid container className={classes.root} spacing={3}>
                                        <Grid item xs={12} md={10} key={key}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={10} md={10}>
                                                    <Text type="subtitle3" component="h3" semibold className="sb-blue2">
                                                        {pkg.title}
                                                    </Text>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Text type="body1" component="h4" className="sb-text body1">
                                                        {pkg.type.length === 2 ? t('profile.pakage.lessons_both') : pkg.type.indexOf('snowboard') > 0 ? t('profile.pakage.lessons_snow') : t('profile.pakage.lessons_ski')}
                                                        {pkg.spots} {pkg.spots === 1 ? t('profile.pakage.person') : t('profile.pakage.persons')}
                                                        {pkg.availability} {pkg.availability === 1 ? t('profile.pakage.lesson') : t('profile.pakage.lessons')}
                                                        {pkg.timeUnit} {pkg.timeUnit === 1 ? t('profile.pakage.hour') : t('profile.pakage.hours')}
                                                        <br/>
                                                        {(pkg.price > 0 &&
                                                            <>
                                                                {t('profile.pakage.price')}
                                                                <span
                                                                    className="sb-text regular">{pkg.price} {place.placeInfo.currency}{pkg.pkgPrice ? t('profile.pakage.price_pkg') : t('profile.pakage.price_pers')}</span>
                                                            </>
                                                        )}
                                                    </Text>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Text type="body" component="p">{pkg.abs && `"`}{pkg.abs}</Text>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={'auto'} md={2} className={classes.btn}>
                                            <Grid container direction="row" alignItems="center"
                                                  justifyContent="flex-end">
                                                <Grid>
                                                    <Button onClick={() => setSelectedPackage(pkg)} variant="outlined"
                                                            color="primary">
                                                        {t('profile.pakage.book')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}
