import React from 'react'
import './assets/style.scss'
import Grid from '@material-ui/core/Grid';
import {Text} from '../../../components/ui-kit/Typo'

import {useTranslation} from 'react-i18next'

export function BookProgress(props) {

    const {t} = useTranslation('iframe');

    let {index, prevStep, maxStep, request, onHandleStep} = props;

    return (
        <Grid item xs={12} style={{padding: '18px'}}>
            <Text type="body">
        <span style={{
            cursor: index === 1 ? 'not-allowed' : 'pointer',
            pointerEvents: index === 1 ? 'none' : 'auto'
        }}
              onClick={index !== 1 ? prevStep : onHandleStep}
              className={index === 1 ? 'disabled' : ''}>
          {`<`} {t('event.book.progress.back')}
        </span>
                {request.pakage.title && ` | ${t('event.book.progress.package')} ${request.pakage.title}`}
            </Text>
        </Grid>
    )
}
