import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams } from "react-router-dom";

import CampForm from './CampForm'
import SubmitSuccess from './SubmitSuccess';
import StepNoRequest from '../../event/book/StepNoRequest';
import StepError from '../../event/book/StepError';

export default function CampBookPage(props){

  let { placeLink, campLink } = useParams();

  const [place, setPlace] = useState();
  const [pakage, setPakage] = useState({});
  const [eventPackages, setEventPackages] = useState([]);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink)
    .then(res => {
      setPlace(res.data);
    },
    err => {
      setError({placeNotFound: true});
    });

    axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink + "/" + campLink)
    .then(res => {
      setPakage(res.data);
    },
    err => {
      setError({pakageNotFound: true});
    });

    axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink + "/package/" + campLink)
    .then(res => {
      setEventPackages(res.data);
    },
    err => {
      setError({eventPackagesNotFound: true});
    });

  }, [placeLink, campLink]);

  return (
    <div>
      {place ?
        <div>
        {place.placeInfo.publicRequest && pakage && eventPackages ?
          <div>
            {success ?
              <SubmitSuccess setSuccess={setSuccess} /> :
              <CampForm
                place={place}
                pakage={pakage}
                eventPackages={eventPackages}
                error={error}
                setError={setError}
                setSuccess={setSuccess} />}
          </div> :
          <StepNoRequest
            place={place} />}
          </div> :
        <StepError />
      }
    </div>
  )
}
