import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

import generalRO from './translation/ro/general.json'
import generalEN from './translation/en/general.json'
import generalES from './translation/es/general.json'

import iframeRO from './translation/ro/iframe.json'
import iframeEN from './translation/en/iframe.json'
import iframeES from './translation/es/iframe.json'

import termsRO from './translation/ro/terms.json'
import termsEN from './translation/en/terms.json'
import termsES from './translation/es/terms.json'

import aboutPageRO from './translation/ro/aboutPage.json'
import aboutPageEN from './translation/en/aboutPage.json'
import aboutPageES from './translation/es/aboutPage.json'

import presentationPageRO from './translation/ro/presentationPage.json'
import presentationPageEN from './translation/en/presentationPage.json'
import presentationPageES from './translation/es/presentationPage.json'

import listingRO from './translation/ro/listing.json'
import listingEN from './translation/en/listing.json'
import listingES from './translation/es/listing.json'

import packageRO from './translation/ro/package.json'
import packageEN from './translation/en/package.json'
import packageES from './translation/es/package.json'

import seoRO from './translation/ro/seo.json'
import seoEN from './translation/en/seo.json'
import seoES from './translation/es/seo.json'


const languages = ['ro', 'en', 'es']

const detectionOptions = {
    order: ['path'],  // , 'navigator' - disabled because if system is in en the path will be in ro and lang in en for /
    lookupFromPathIndex: 0,
};

const resources = {
    ro: {
        general: generalRO,
        iframe: iframeRO,
        terms: termsRO,
        aboutPage: aboutPageRO,
        presentationPage: presentationPageRO,
        listing: listingRO,
        package: packageRO,
        seo: seoRO
    },
    en: {
        general: generalEN,
        iframe: iframeEN,
        terms: termsEN,
        aboutPage: aboutPageEN,
        presentationPage: presentationPageEN,
        listing: listingEN,
        package: packageEN,
        seo: seoEN
    },
    es: {
        general: generalES,
        iframe: iframeES,
        terms: termsES,
        aboutPage: aboutPageES,
        presentationPage: presentationPageES,
        listing: listingES,
        package: packageES,
        seo: seoES
    }
}

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'ro',
        //lng: 'ro',
        debug: true,
        // keySeparator: false, // we do not use keys in form messages.welcome
        debug: false,
        whitelist: languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: detectionOptions,
        react: {
            wait: true,
        },
    });

export default i18next;
