import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import ViewTerm from './ViewTerm';

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function Terms(props) {

  let { place, request, accept } = props
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);;

  const { t } = useTranslation('iframe');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    accept();
    setOpen(false);
  };

  return (
    <span>
       {t('event.book.terms.electronically_sign')} <span onClick={handleClickOpen} className={'sb-red1 pointer'}>{t('event.book.terms.agreement')}</span>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton edge="start" className={'sb-light'} onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            {t('event.book.terms.participation_agreement')}
            </Typography>
            <Button variant="outlined" className={'sb-btn-light'} onClick={handleAccept}>
            {t('event.book.terms.accept_btn')}
            </Button>
          </Toolbar>
        </AppBar>
        {place.placeInfo.localTerms &&
          <ViewTerm
            place={place}
            request={request} /> }
      </Dialog>
    </span>
  );
}
