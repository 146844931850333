import React from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// assets

import PresentPage from './PresentPage'
import { Text } from '../../components/ui-kit/Typo'

import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

export default function AboutPage(props) {

  const [open, setOpen] = React.useState(false);

  let { lang } = useParams();
  const { t } = useTranslation('aboutPage');

  return (
    <Grid container spacing={3} className="bg-logo">
      <Grid item xs={12}>
        <Text type="title3" className={'sb-red1'}>
          {t('greetings')}
        </Text>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Text type="subtitle" component="h1" className={'sb-blue1 subtitle3'} uppercase={false} semibold={true}>
          {t('video.title')}
        </Text>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Text type="title3" className={'sb-blue1'}>
          {t('video_section.title')}
        </Text>
        <Text type="subtitle3">{t('video_section.subtitle')}</Text>
        <Text type="body">{t('video_section.p1')} <br/>
        {t('video_section.p2')}</Text>
      </Grid>
      <Grid item xs={12}>
        <video width="100%" style={{maxWidth: '750px'}} controls autoplay>
          <source src="https://snowbuddy.app/pow-demo.mp4" type="video/mp4" />
          <track
            kind="subtitles"
            src={lang === 'en' ? "/pow-demo-en.mp4.vtt" : lang === 'es' ? "/pow-demo-es.mp4.vtt" : ""}
            srcLang={lang}
            label={lang === 'en' ? 'English' : 'Español'}
            default
          />
        </video>
      </Grid>
      <Grid item xs={12}>
        <Text type="title3" className={'sb-blue1'}>
        {t('how_does_this_works.title')}
        </Text>
        <Text type="subtitle3">{t('how_does_this_works.subtitle')}</Text>
        <Text type="body">{t('how_does_this_works.p1')}</Text>
        <br/>
        <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        {t('how_does_this_works.presentation_btn')}
        </Button> <span> </span>
        <br/><br/>
        <Text type="body">{t('how_does_this_works.p2')}</Text>
        <br/>
        <a href={`https://snowbuddy.app/${lang}/iframe/snow-buddy/event/book`} target="_blank"><Button variant="outlined" color="primary">
          {t('how_does_this_works.iframe_btn')}
        </Button></a>
        <br/><br/>
        <PresentPage
          open={open}
          handleClose={() => setOpen(false)} />
      </Grid>
      <Grid item xs={12}>
        <Text type="title3" className={'sb-blue1'}>
        {t('what_is_snowbuddy.title')}
        </Text>
        <Text type="subtitle3">{t('what_is_snowbuddy.subtitle')}</Text>
        <Text type="body">{t('what_is_snowbuddy.p1')}
        <br/>{t('what_is_snowbuddy.br1_1')}
        <br/>{t('what_is_snowbuddy.br1_2')}</Text>
        <Text type="body">{t('what_is_snowbuddy.p2')}</Text>
        <Text type="body"> {t('how_does_this_works.demo_explication')}
        <br/>{t('how_does_this_works.demo_explication_br')} <a href="https://snowbuddy.app/en/terms" className="privacy" target="_blanc">{t('how_does_this_works.terms_and_conds')}</a> </Text>
      </Grid>
      <Grid item xs={12}>
        <Text type="title3" className={'sb-blue1'}>
        {t('it_helps_me.title')}
        </Text>
        <Text type="subtitle3">{t('it_helps_me.subtitle')}</Text>
        <Text type="body">{t('it_helps_me.p1')}
        <br/>{t('it_helps_me.br1_1')}
        <br/>{t('it_helps_me.br1_2')}</Text>
        <Text type="subtitle3">{t('integrations.subtitle')}</Text>
        <Text type="body">
          <ul>
            <li><span style={{fontWeight: 600}} className={'sb-purple'}>SmartBill</span> {t('integrations.smartbill')}</li>
            <li><span style={{fontWeight: 600}} className={'sb-purple'}>Banca Transilvania</span> {t('integrations.bt')}</li>
            <li><span style={{fontWeight: 600}} className={'sb-purple'}>{t('integrations.termo_title')}</span> {t('integrations.termo')}</li>
            <li><span style={{fontWeight: 600}} className={'sb-purple'}>{t('integrations.sms_title')}</span> {t('integrations.sms')}</li>
          </ul>
        </Text>
        <Text type="subtitle3">{t('organize.subtitle')}</Text>
        <Text type="body">{t('organize.p1')}
        <br/>{t('organize.p2')}</Text>
        <Text type="body">
          <ul>
            <li>{t('organize.pkg_simple')}</li>
            <li>{t('organize.pkg_course')}</li>
            <li>{t('organize.pkg_camp')}</li>
            <li>{t('organize.pkg_experience')}</li>
            <li>{t('organize.pkg_multiple_hours')}</li>
            <li>{t('organize.pkg_clients')}</li>
          </ul>
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text type="title3" className={'sb-blue1'}>
        {t('who_did_this.title')}
        </Text>
        <Text type="subtitle3">{t('who_did_this.subtitle')}</Text>
        <Text type="body">{t('who_did_this.p1')}
        <br/>{t('who_did_this.p1_2')}
        <br/>{t('who_did_this.p1_3')}
        <br/>{t('who_did_this.br1_1')}
        <br/>{t('who_did_this.br1_2')}</Text>
        <Text type="body">{t('who_did_this.p2')} <a href="https://www.instagram.com/snowbuddy.app/" target="_blank">@snowbuddy.app</a></Text>
      </Grid>
      <Grid item xs={12}>
        <Text type="title3" className={'sb-blue1'}>
        {t('tester.title')}
        </Text>
        <Text type="subtitle3">{t('tester.subtitle')}</Text>
        <Text type="body">{t('tester.p1')} <a href={t('tester.mailto')}>Hello SnowBuddy App</a> {t('tester.wazzap')} <a href={t('tester.wazzap_link')}>WhatsApp</a></Text>
      </Grid>
    </Grid>
    );
  }
