import React from 'react'
import { useParams, NavLink } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Text } from '../../../components/ui-kit/Typo';
import { Papir } from '../../../components/ui-kit/Papir';

export default function PlaceHeader({ place }) {

  const { lang } = useParams();
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>

      </Grid>
      <Grid item>
        <NavLink to={location => `/${lang}/${place.link}`}>
          <Papir className="white">
            <img style={{ width: '100px' }}
                src={`${process.env.REACT_APP_MAPI_URL}/public/images/${place.link}-logo`} />
          </Papir>
        </NavLink>
      </Grid>
      <Grid item>
        <NavLink to={location => `/${lang}/${place.link}`}>
          <Text type="title2" component="h1" style={{margin: 0}}>
              {place.name}
          </Text>
        </NavLink>
      </Grid>
    </Grid>
  )
}