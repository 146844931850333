import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
 paper: {
   textAlign: 'left',
   justifyContent: 'center',
   alignItems: 'center'
 },
}));

export function Papir(props) {

 const [inHover, setHover] = useState(false)

 const classes = useStyles();
 const { className, align, spacing } = props;
 const theme = useTheme();


 console.log()
 return (
   <Paper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`sb-papir ${classes.paper} ${className}`}
      style={{textAlign: align, padding: theme.spacing(spacing)}}>

        { props.children }

    </Paper>
 );
}


Papir.propTypes = {
  //type: PropTypes.oneOf(['title', 'title2', 'subtitle', 'subtitle2', 'title3', 'subtitle3', 'body']),
  className: PropTypes.string,
  align: PropTypes.string,
  spacing: PropTypes.number,
}

Papir.defaultProps = {
  type: 'p',
  align: 'left',
  spacing: 2,
  content: 'center'
};
