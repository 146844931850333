import React, { useState, useEffect } from 'react'
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import { Text } from '../../../../components/ui-kit/Typo'
import Button from '@material-ui/core/Button';
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import EventsList from './EventsList'

export default function Events(props){

  let { place } = props;

  const { t } = useTranslation('iframe');
  let { placeLink, lang } = useParams();

  const [events, setEvents] = useState([])
  const [error, setError] = useState(false)
  const [newBook, setNewBook] = useState(false);

  useEffect(() => {
    getEvents();
  },[])

  const getEvents = () => {
    axios.get(process.env.REACT_APP_UI_SERVICE + '/place/' + placeLink + '/event/client')
    .then(res => {
      setEvents(res.data)
    },
    err => {
      setError(true)
    });
  }

  const cancelEvent = event => {
    axios.post(process.env.REACT_APP_UI_SERVICE + '/place/' + placeLink + '/event/cancel', event)
    .then(res => {
      setEvents(res.data)
    },
    err => {
      setError(true)
    });
  }

  return(
    <Grid container
      spacing={3}
      justify="space-around"
      alignItems="center">
      <Grid item md={4}>
        <Text type="subtitle" uppercase>{t('event.edit.events.title')}</Text>
      </Grid>
      <Grid item md={8} xs={12}>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <EventsList
              events={events}
              resources={place.resources}
              cancelEvent={cancelEvent}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Text type="body2">
              {t('event.edit.events.session_info')}
            </Text>
          </Grid>
          <Grid item xs={12} md={6} className={'text-right'}>
            <Button color="secondary" variant="outlined" onClick={() => setNewBook(true)}>{t('event.edit.events.btn_new_book')}</Button>
          </Grid>
        </Grid>
      </Grid>

      {error && <Redirect to={`/${lang}/iframe/${placeLink}/event/edit`}/>}
      {newBook && <Redirect to={`/${lang}/iframe/${placeLink}/event/book`}/>}
    </Grid>
  )
}
