import React, { useEffect } from 'react'
import { Route, Switch as RoutingSwitch, useLocation } from 'react-router-dom'

import { Container } from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useDarkMode } from '../components/hooks/darkMode'
import Page404 from '../components/general/404'

import EventBookPage from './event/book/EventBookPage';
import EventEditPage from './event/edit/EventEditPage';
import ValidateRequestPage from './event/validate/ValidateRequestPage';

import ClientPackageTermsPage from './client/terms/ClientPackageTermsPage';
import PackagePage from './package/PackagePage'

import CampBookPage from './camp/book/CampBookPage';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function IFramePage(props){

  let query = useQuery();
  let { toggleDarkMode } = props;
  const userPrefDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // set theme if user preffers or exist in query string
  useEffect(() => {
    if (query.get('theme') === null)
      toggleDarkMode(userPrefDarkMode)
    else
      toggleDarkMode(query.get('theme') == 'dark' ? true : false)
  }, [userPrefDarkMode])

  return (
    <Container maxWidth="lg" style={{minHeight: '100%', padding: `${query.get('pad') ? query.get('pad') : '24px'}`, background: `${query.get('bg') ? ('#' + query.get('bg')) : 'inherit'}`, color: `${query.get('text') ? ('#' + query.get('text')) : 'inherit'}`, minWidth: query.get('mwidth') ? `${query.get('mwidth')}%` : 'inherit'}}>
      <RoutingSwitch>
        <Route path={`/:lang(en|ro|es)/iframe/event/book/:placeLink`} exact component={EventBookPage} />
        <Route path={`/:lang(en|ro|es)/iframe/event/edit/:placeLink`} component={EventEditPage} />
        <Route path={`/:lang(en|ro|es)/iframe/event/validate/:placeLink/:token`} component={ValidateRequestPage} />

        <Route path={`/:lang(en|ro|es)/iframe/:placeLink/event/book`} exact component={EventBookPage} />
        <Route path={`/:lang(en|ro|es)/iframe/:placeLink/event/edit`} exact component={EventEditPage} />
        <Route path={`/:lang(en|ro|es)/iframe/:placeLink/event/validate/:token`} exact component={ValidateRequestPage} />

        <Route path={`/:lang(en|ro|es)/iframe/:placeLink/camp/book/:campLink`} exact component={CampBookPage} />

        <Route path={`/:lang(en|ro|es)/iframe/:placeLink/terms/:cpid`} exact component={ClientPackageTermsPage} />

        <Route path={`/:lang(en|ro|es)/iframe/:placeLink/package`} exact component={PackagePage} />

        <Route path={`/:lang(en|ro|es)/iframe/*`} component={Page404} />
      </RoutingSwitch>
    </Container>
  )
}
