import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import { Text } from '../../components/ui-kit/Typo'
import { useTranslation } from 'react-i18next';

export default function PackageAuth(props){

  let { getPackage, getError } = props;
  const { t } = useTranslation('package')

  const [details, setDetails] = useState({});
  const [error, setError] = useState({});

  return (
    <Grid container spacing={3} justify="space-around" alignItems="center">

      <Grid item md={4}>
        <Text type="subtitle" uppercase>{t('get.title')}</Text>
      </Grid>

      <Grid item md={8} xs={12}>
        <Grid container spacing={3} justify="space-around" alignItems="center">

          <Grid item xs={12}>
            <Text type="subtitle2">{t('get.subtitle')}</Text>
            <Text type="body">{t('get.error')}</Text>
          </Grid>

          {false && <div><Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="phone"
              autocomplete="phone"
              label={t('get.phone')}
              onChange = {(event, newValue) => {
                var newVal = event.target.value;
                setDetails({...details, phone:newVal})
              }}
              value={details.phone}
              error={error.phone}
              helperText={error.phone ? `${t('get.phone_helper')}` : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="reqId"
              label={t('get.reqId')}
              onChange = {(event, newValue) => {
                var newVal = event.target.value;
                setDetails({...details, id:newVal})
              }}
              value={details.id}
              error={error.reqId}
              helperText={error.reqId ? `${t('get.reqId_helper')}` : ''}
            />
          </Grid>

          <Grid item xs={12} className={"text-center"}>
            <Button variant="outlined" color="secondary" onClick={() => getPackage(details)}>
              {t('get.details_btn')}
            </Button>
            <br/><br/>
          </Grid>

          <Grid item xs={12} className={"text-center"}>
            {getError ?
              <Text type="body" color="error">{t('get.error')}</Text> : ''}
          </Grid></div>}
        </Grid>
      </Grid>
    </Grid>
  )
}
