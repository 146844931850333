import React from 'react'
import {animated} from 'react-spring'

import Grid from '@material-ui/core/Grid';
import {Text} from '../../../components/ui-kit/Typo'
import Button from '@material-ui/core/Button';

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import {useTranslation} from 'react-i18next'
import Seo from "../../../components/general/Seo";

export function Step6(props) {

    let {style, onHandleStep, place, request} = props;
    const [dates, setDates] = React.useState({
        start: new Date(Date.parse(request.start)),
        end: new Date(Date.parse(request.end))
    });
    const onChange = dates => {
        let [start, end] = dates;
        setDates({start: start, end: end});
    };

    const {t} = useTranslation(['iframe', 'seo']);

    const packageTitle = request.pakage ? request.pakage.title : 'Default Title';

    return (
        <animated.div style={{...style, padding: '16px'}}>
            <Seo title={`${t('general:nav.items.package')} ${packageTitle} - ${t('seo:seo_book.step6.title')}`}
                 description={t('seo:seo_book.step6.description')}/>

            <Grid container spacing={3} justify="space-around" alignItems="center">

                <Grid item md={4}>
                    <Text type="subtitle" uppercase>{t('event.book.step5.reservation_period')}</Text>
                </Grid>

                <Grid item md={8} xs={12}>
                    <Grid container spacing={3} justify="space-around" alignItems="center">

                        <Grid item xs={12} md={5} className={"text-center"}>
                            <DatePicker
                                onChange={onChange}
                                startDate={dates.start}
                                endDate={dates.end}
                                minDate={new Date(Date.parse(place.placeInfo.startTime))}
                                maxDate={new Date(Date.parse(place.placeInfo.closeTime))}
                                dateFormat="DD/MM/yyyy"
                                selectsRange
                                inline
                                disabledKeyboardNavigation
                            />
                        </Grid>

                        <Grid item xs={12} md={5} className={"text-center"}>
                            <Text
                                type="subtitle3">{t('event.book.step5.start_time')} {moment(dates.start).format('DD/MM/yyyy')}</Text>
                            <Text
                                type="subtitle3">{t('event.book.step5.end_time')} {dates.end ? moment(dates.end).format('DD/MM/yyyy') : t('event.book.step5.one_day')}</Text>
                        </Grid>

                        <Grid item xs={12} className={"text-center"}>
                            <Button variant="outlined" color="primary" onClick={() => onHandleStep(dates)}
                                    disabled={!moment(dates.start).isBetween(place.placeInfo.startTime, place.placeInfo.closeTime)}>
                                Next >
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </animated.div>
    )
}
