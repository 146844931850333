import React, { useRef } from 'react';
import Content from './Content';
import Header from './Header';

const ViewTerm = (props) => {

  let { place, request } = props;
  const componentRef = useRef();

  return (
    <div ref={componentRef} className={'pdf-page'}>
      <Header place={place} />
      <Content
        term={place.placeInfo.localTerms}
        request={request} />
    </div>
  );
};

export default ViewTerm
