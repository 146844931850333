import React, { useState } from 'react'
import { Grid, Button, Divider } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import { useTranslation } from 'react-i18next'

export default function EventItem(props) {

    const { t } = useTranslation('iframe');
    let { event, resources, cancelEvent } = props;

    const [askCancel, setAskCancel] = useState(false);
    const [loading, setLoading] = useState(false);

    const getStaff = id => {
      for (var key in resources) {
        if (resources[key].id === id) return resources[key];
      }
    }

    return (
      <Grid container justify="center" alignItems="center" spacing={1} className="gridContainer">
        <Grid item xs={6} md={3}>
          {event.title}
        </Grid>
        <Grid item xs={6} md={2}>
          {moment(event.startDate).format("DD.MM.YY, HH.mm")}
        </Grid>
        <Grid item xs={4} md={3}>
          {event.pakage.name}
        </Grid>
        <Grid item xs={4} md={2}>
          {getStaff(event.resourceId).name}
        </Grid>

        <Grid item xs={4} md={2} className={'text-right'}>
          {loading ?
            <Button color="primary" variant="outlined">
              <CircularProgress color="secondary" size={25}/>
            </Button> :
            <div> {askCancel ?
              <Button title={t('event.edit.events.event_item.cancel_btn_confirm')} color="primary" variant="outlined" onClick={() => {cancelEvent(event); setAskCancel(false); setLoading(true)}}>
                Confirm!
              </Button> :
              <Button title={t('event.edit.events.event_item.cancel_btn_title')} color="primary" variant="outlined"
                  onClick={() => {
                    setAskCancel(true);
                    setTimeout(() => {setAskCancel(false);}, 2000);}}>
                <DeleteIcon />
              </Button> }
            </div>
          }
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
      </Grid>
    )
}
