import React, {useEffect} from "react";
import {Helmet} from 'react-helmet';
import {useParams, useLocation} from 'react-router-dom'

export default function Seo(props) {
    let {title, description, image} = props;

    let {lang} = useParams();

    const location = useLocation();

    useEffect(() => {
        console.log("Location", location)
        console.log(lang);
    }, [location])
    
    const getAlternateLangs = (currentLang) => {
        const langs = ['ro', 'en', 'es'];
        return langs.filter(l => l !== currentLang);
    }

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>

            {/*Facebook*/}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`https://snowbuddy.app${location.pathname}`}/>
            {image && <meta property="og:image" content={`https://snowbuddy.app${image}`}/>}
            {image && <meta property="og:image:secure_url" content={`https://snowbuddy.app${image}`}/>}
            {image && <meta name="og:image_secure" content={`https://snowbuddy.app${image}`}/>}

            {/*Twitter*/}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            {image && <meta name="twitter:image" content={`https://snowbuddy.app${image}`}/>}
            <meta name="twitter:site" content="@snowbuddy_app"/>
            <meta name="twitter:creator" content="@snowbuddy_app"/>

            {getAlternateLangs(lang).map(altLang => (
                <link key={altLang} rel="alternate" hrefLang={altLang}
                      href={`https://snowbuddy.app/${altLang}${location.pathname.slice(3)}`}/>
            ))}

        </Helmet>
    )
}
