import React from 'react';
import Grid from '@material-ui/core/Grid';

export function Spacer(props) {

 return (
   <Grid item xs className={'spacer'}/>
 );
}


Spacer.propTypes = {
}

Spacer.defaultProps = {
};
