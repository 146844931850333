import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import axios from 'axios'

import Seo from '../../../components/general/Seo'

import {Grid} from '@material-ui/core'
import {Text} from '../../../components/ui-kit/Typo'
import {useTranslation} from 'react-i18next'
import JsonSchema from '../../../components/general/JsonSchema'

import CampBookPage from './CampBookPage'

import {convertToHTML} from 'draft-convert';
import {convertFromRaw} from 'draft-js';

export default function PlacePackage(props) {

    let {placeLink, packageLink} = useParams();
    const {t, i18n} = useTranslation('listing');
    let {place} = props;

    const [pkg, setPkg] = useState({});
    const [eventPackages, setEventPackages] = useState([]);
    const [error, setError] = useState({});

    const getPackage = link => {
        for (var key in place.placePakage) {
            if (place.placePakage[key].link === link) return place.placePakage[key]
        }
    }
    let {lang} = useParams();

    useEffect(() => {
        setPkg(getPackage(packageLink))
    }, [packageLink])

    useEffect(() => {
        axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink + "/package/" + packageLink)
            .then(res => {
                    setEventPackages(res.data);
                },
                err => {
                    setError({eventPackagesNotFound: true});
                });
    }, [packageLink])

    return (
        <Grid item xs={12}>
            <Seo title={`${pkg.title} - ${pkg.type} - ${place.name} | SnowBuddy.app`}
                 description={pkg.abs}
                 image={`/camps/${place.link}/${pkg.link}/1.jpg`}/>

            <Grid container spacing={5}>
                {pkg.link && <JsonSchema packages={[pkg]} place={place} lang={lang}/>}
                <Grid item xs={12}>
                    <Text type="subtitle" component="h2" semibold className="sb-blue2">
                        {pkg.title}
                    </Text>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={8}>
                            <img src={`/camps/${place.link}/${pkg.link}/1.jpg`} alt=""
                                 style={{maxWidth: '100%', borderRadius: '15px', cursor: 'pointer'}}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <img src={`/camps/${place.link}/${pkg.link}/2.jpg`} alt=""
                                         style={{maxWidth: '100%', borderRadius: '15px', cursor: 'pointer'}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <img src={`/camps/${place.link}/${pkg.link}/3.jpg`} alt=""
                                         style={{maxWidth: '100%', borderRadius: '15px', cursor: 'pointer'}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Text type="subtitle2" component="h6">
                        {t('profile.pakage.info')}
                    </Text>
                    <Grid item xs={12}>
                        {pkg.type && <Text type="body1" component="h4" className="sb-text body1">
                            {pkg.type.length == 2 ? t('profile.pakage.camp_both') : pkg.type.indexOf('snowboard') > 0 ? t('profile.pakage.camp_snow') : t('profile.pakage.camp_ski')}
                            {pkg.availability} {pkg.availability == 1 ? t('profile.pakage.lesson') : t('profile.pakage.lessons')}
                            {pkg.timeUnit} {pkg.timeUnit == 1 ? t('profile.pakage.hour') : t('profile.pakage.hours')}
                            <br/>
                            {t('profile.pakage.price')}
                            <span
                                className="sb-text regular">{pkg.price} {pkg.place && pkg.place.placeInfo.currency}{pkg.pkgPrice ? t('profile.pakage.price_pkg') : t('profile.pakage.price_pers')}</span>
                        </Text>}
                        <Text type="body1" component="h4" className="sb-text body1">{pkg.abs}</Text>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Text type="subtitle2" component="h6">
                        {t('profile.pakage.desc')}
                    </Text>
                    {pkg.info &&
                        <div dangerouslySetInnerHTML={{__html: convertToHTML(convertFromRaw(JSON.parse(pkg.info)))}}/>}
                </Grid>

                <Grid item xs={12}>
                    <CampBookPage pakage={pkg} place={place}/>
                </Grid>

            </Grid>
        </Grid>
    )
}
