import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'

import screenapp from '../../assets/img/about/screen-app.png'
import screenadmin from './assets/about/dashboard-simple-flow_ro.gif'
import screeniframe from './assets/about/iframe-simple-flow_ro.gif'
import './assets/about/aboutPage.scss'

import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import DevicesIcon from '@material-ui/icons/Devices';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PresentPage(props) {

  let [step, setStep] = useState(1);
  let maxStep = 4;
  const nextStep = () => {
    if (step === maxStep) {
      setStep(1);
      props.handleClose();
    } else {
      setStep(step + 1);
    }
  }

  const prevStep = () => {
    if (step != 1)
      setStep(step - 1);
  }

  const { t } = useTranslation('presentationPage');

  return(
    <Dialog fullScreen open={props.open} onClose={() => props.handleClose()} TransitionComponent={Transition}>
      <Grid item xs={1}>
        <IconButton edge="start" color="inherit" onClick={() => props.handleClose()} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Grid>
      <Container maxWidth="lg" className={'container-present'}>
        <Grid container justify="space-around" alignItems="center" className={'grid-c-present'}>
          <Grid item xs={1}>
            {step === 1 ? '' : <KeyboardArrowLeftIcon onClick={prevStep} />}
          </Grid>
          <Grid item xs={10} className={'full-heigh'}>
            <Slide direction="up" in={step === 1 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {t('components')}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <DevicesIcon color='secondary' className={'icon'} />
                  <Typography variant="h6">
                  {t('for')} <span style={{fontWeight: 600}} className={'sb-purple'}>{t('admins')}</span>
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <PhoneIphoneIcon color='secondary' className={'icon'} />
                  <Typography variant="h6">
                  {t('for')} <span style={{fontWeight: 600}} className={'sb-purple'}>{t('coaches')}</span>
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormatShapesIcon color='secondary' className={'icon'} />
                  <Typography variant="h6">
                  {t('for')} <span style={{fontWeight: 600}} className={'sb-purple'}>{t('reservations')}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="down" in={step === 2 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                  {t('admin.panel')} <span className={'sb-green1'}>{t('admin.name')}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div class="img">
                    <img src={screenadmin} alt="Screen admin" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>{t('admin.description')}</h5>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li><span class="checked">&#10004;</span>{t('admin.li_1')}</li>
                    <li><span class="checked">&#10004;</span>{t('admin.li_2')}</li>
                    <li><span class="checked">&#10004;</span>{t('admin.li_3')}</li>
                    <li><span class="checked">&#10004;</span>{t('admin.li_4')}</li>
                    <li><span class="checked">&#10004;</span>{t('admin.li_5')}</li>
                    <li><span class="checked">&#10004;</span>{t('admin.li_6')}</li>
                    <li><span class="checked">&#10004;</span>{t('admin.li_7')}</li>
                  </ul>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="left" in={step === 3 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                  {t('coach.app')} <span className={'sb-green1'}>{t('coach.name')}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div class="img">
                    <img src={screenapp} alt="Screen app" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>{t('coach.description')}<a href="https://play.google.com/store/apps/details?id=snowbuddy.app">{t('coach.android')}</a> {t('coach.and')} <a href="https://itunes.apple.com/us/app/snowbuddy/id1449318224?ls=1&mt=8">{t('coach.IOS')}</a></h5>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li><span class="checked">&#10004;</span>{t('coach.li_1')}</li>
                    <li><span class="checked">&#10004;</span>{t('coach.li_2')}</li>
                    <li><span class="checked">&#10004;</span>{t('coach.li_3')}</li>
                    <li><span class="checked">&#10004;</span>{t('coach.li_4')}</li>
                    <li><span class="checked">&#10004;</span>{t('coach.li_5')}</li>
                  </ul>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="right" in={step === 4 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                  {t('online_reservations.module')} <span className={'sb-green1'}>{t('online_reservations.name')}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div class="img">
                    <img src={screeniframe} alt="Screen iframe" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>{t('online_reservations.description')}</h5>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li><span class="checked">&#10004;</span>{t('online_reservations.li_1')}</li>
                    <li><span class="checked">&#10004;</span>{t('online_reservations.li_2')}</li>
                    <li><span class="checked">&#10004;</span>{t('online_reservations.li_3')}</li>
                    <li><span class="checked">&#10004;</span>{t('online_reservations.li_4')}</li>
                    <li><span class="checked">&#10004;</span>{t('online_reservations.li_5')}</li>
                  </ul>
                </Grid>
              </Grid>
            </Slide>

          </Grid>
          <Grid item xs={1}>
            <KeyboardArrowRightIcon onClick={nextStep} />
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}
