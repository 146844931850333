import React, { useState, useEffect } from 'react'
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Text } from '../../../components/ui-kit/Typo'

import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next'

export default function OtpAuth(props){

  let {place, email, otp} = props;

  const { t } = useTranslation('iframe');
  let { placeLink, lang } = useParams();

  const [credentials, setCredentils] = useState({email: email, otp: otp, grant_type: 'password', client_id: 'snowpow-web'})
  const [otpRequest, setOtpRequest] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (otp != undefined)
      auth();
    else if (email != undefined)
      requestOtp();
  },[email, otp])

  const requestOtp = () => {
    setLoading(true);
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink + '/event/otp', credentials)
    .then(res => {
      setOtpRequest(res.data);
      setLoading(false);
    },
    err => {
      setError({requestOtpError: true});
      setLoading(false);
    });
  }

  const auth = () => {
    setLoading(true);
    const body = 'client_id=snowpow-web&grant_type=password&password=' + credentials.otp + '&username=' + credentials.email;
    axios.post(process.env.REACT_APP_UI_SERVICE + '/oauth/token', body, {headers: {'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'}})
    .then(res => {
      setIsAuth(true);
    },
    err => {
      setError({authError: true});
      setLoading(false);
    });
  }

  return(
    <Grid container
      spacing={3}
      justify="space-around"
      alignItems="center">
      <Grid item md={4}>
        <Text type="subtitle" uppercase>{t('event.edit.authenticate.title')}</Text>
      </Grid>
      <Grid item md={8} xs={12}>

          {loading ? <Grid item xs={12}>
            <Grid container justify="space-around" alignItems="center">
              <Grid item>
                <CircularProgress color="secondary"/>
              </Grid>
            </Grid>
          </Grid> :
          <Grid container spacing={3} justify="space-around" alignItems="center">
            <Grid item xs={12}>
              <form noValidate>
                <Grid container spacing={3} justify="space-around" alignItems="center">
                  {!otpRequest ? <Grid item md={7} xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      type="email"
                      autocomplete="email"
                      label={t('event.edit.authenticate.email')}
                      onChange = {(event,newValue) => {
                        var newVal = event.target.value;
                        setCredentils(prevValues=>({...prevValues, email:newVal}))
                      }}
                      value={credentials.email}
                      error={error.email}
                      helperText={error.email ? `${t('event.edit.authenticate.email_helperText')}` : ''}
                    />
                  </Grid> :
                  <Grid item xs={12}>
                    <Text type="subtitle2">
                      Link trimis!
                    </Text>
                    <Text type="body">
                      Te rugam sa accesezi link-ul de autentificare trimis la adresa <span color="secondary">{email} </span>
                      <br />
                      p.s. verifica si in spam :)
                    </Text>
                    <br />
                    <Text type="subtitle3">
                      Dar daca nu am primit email-ul?
                    </Text>
                    <Text type="body">
                      Daca nu ai primit email-ul te rugam sa ne contactezi la numarul de telefon <a href={`tel:${place.placeInfo.phone}`}>{place.placeInfo.phone}</a>
                    </Text>
                  </Grid>}
                </Grid>
              </form>
            </Grid>

            <Grid item xs={12} className={"text-center"}>
              {!otpRequest &&
                <Button variant="outlined" color="secondary" onClick={() => requestOtp()} disabled={false}>
                  {t('event.edit.authenticate.otp_btn')}
                </Button>}
            </Grid>
          </Grid>}

        </Grid>

        {isAuth && <Redirect to={`/${lang}/iframe/${placeLink}/event/edit/events`}/>}
    </Grid>
  )
}
