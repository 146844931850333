import React, { useState } from 'react'
import { useTransition } from 'react-spring'
import './assets/style.scss'
import { useTranslation } from 'react-i18next';

import { BookProgress } from './BookProgress'
import { Step1 } from './Step1'     // package
import { Step2 } from './Step2'     // type - ski / snb
import { Step3 } from './Step3'     // location
import { Step4 } from './Step4'     // attendees
import { Step5 } from './Step5'     // level
import { Step6 } from './Step6'     // period
import { Step7 } from './Step7'     // preffers
import { Step8 } from './Step8'   // payment
import { Step9 } from './Step9'     // details
import { Step10 } from './Step10'     // all good?
// import { Step11 } from './Step11'
import PackageDetails from '../../package/PackageDetails'   // after submit
import { Step12 } from './Step12'   // error

const pages = [
  ({ style, onHandleStep, place, request}) => <Step1 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step2 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step3 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step4 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step5 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step6 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step7 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step8 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step9 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <Step10 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
  ({ style, onHandleStep, place, request}) => <PackageDetails style={style} onHandleStep={onHandleStep} place={place} eventPackage={request}/>,
  ({ style, onHandleStep, place, request}) => <Step12 style={style} onHandleStep={onHandleStep} place={place} request={request}/>,
]

function Book(props) {

  const { t } = useTranslation(['iframe']);
  let { step, maxStep, place, request } = props;

  let fromRight = 'translate3d(100%,0,0)';
  let fromLeft = 'translate3d(-100%,0,0)';
  let leaveRight = 'translate3d(-50%,0,0)';
  let leaveLeft = 'translate3d(50%,0,0)';
  let [back, setBack] = useState(false);
  let transitions = useTransition(step - 1, p => p, {
    from: { opacity: 0, transform: back ? fromLeft : fromRight },
    enter: { opacity: 1, transform: `translate3d(0%,0,0)` },
    leave: { opacity: 0, transform: back ? leaveLeft : leaveRight},
  })

  const handlePrevStep = () => {
    setBack(true);
    props.prevStep();
  }

  const handleOnHandleStep = value => {
    setBack(false);
    props.onHandleStep(value);
  }

  return (
    <div style={{position: 'relative', minHeight: '440px', width: '100%', maxWidth: '100vw', margin: 'auto'}}>
      <BookProgress
        index={step}
        maxStep={maxStep}
        prevStep={handlePrevStep}
        request={request}
        onHandleStep={handleOnHandleStep}
        t={t} />
      <div className="simple-trans-main">
        {transitions.map(({ item, props, key }) => {
          const Page = pages[item]
          return <Page
            key={key}
            style={props}
            onHandleStep={handleOnHandleStep}
            place={place}
            request={request}/>
        })}
      </div>
    </div>
  )
}

export default Book;
