import React, {useState} from 'react'
import {useTransition} from 'react-spring'
import '../../../iframe/event/book/assets/style.scss'
import {useTranslation} from 'react-i18next';

import {BookProgress} from './BookProgress'

import {Step1} from '../../../iframe/event/book/Step1'
import {Step2} from '../../../iframe/event/book/Step2'
import {Step3} from '../../../iframe/event/book/Step3'
import {Step4} from '../../../iframe/event/book/Step4'
import {Step5} from '../../../iframe/event/book/Step5'
import {Step6} from '../../../iframe/event/book/Step6'
import {Step7} from '../../../iframe/event/book/Step7'
import {Step8} from '../../../iframe/event/book/Step8'
import {Step9} from '../../../iframe/event/book/Step9'
import {Step10} from '../../../iframe/event/book/Step10'
// import { Step11 } from '../../../iframe/event/book/Step11'
import PackageDetails from '../../../iframe/package/PackageDetails'
import {Step12} from '../../../iframe/event/book/Step12'

import Seo from "../../../components/general/Seo";

const pages = [
    ({style, onHandleStep, place, request}) => <Step1 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step2 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step3 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step4 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step5 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step6 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step7 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step8 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step9 style={style} onHandleStep={onHandleStep} place={place}
                                                      request={request}/>,
    ({style, onHandleStep, place, request}) => <Step10 style={style} onHandleStep={onHandleStep} place={place}
                                                       request={request}/>,
    ({style, onHandleStep, place, request}) => <PackageDetails style={style} onHandleStep={onHandleStep} place={place}
                                                               eventPackage={request}/>,
    ({style, onHandleStep, place, request}) => <Step12 style={style} onHandleStep={onHandleStep} place={place}
                                                       request={request}/>,
]

function Book(props) {
    const {t} = useTranslation(['iframe', 'seo']);

    let {setSelectedPackage, step, maxStep, place, request, onHandleStep, prevStep} = props;

    let fromRight = 'translate3d(100%,0,0)';
    let fromLeft = 'translate3d(-100%,0,0)';
    let leaveRight = 'translate3d(-50%,0,0)';
    let leaveLeft = 'translate3d(50%,0,0)';
    let [back, setBack] = useState(false);
    let transitions = useTransition(step - 1, p => p, {
        from: {opacity: 0, transform: back ? fromLeft : fromRight},
        enter: {opacity: 1, transform: `translate3d(0%,0,0)`},
        leave: {opacity: 0, transform: back ? leaveLeft : leaveRight},
    })

    const handlePrevStep = () => {
        setBack(true);
        props.prevStep();
    }

    const handleOnHandleStep = value => {
        setBack(false);
        props.onHandleStep(value);
    }

    return (
        <div style={{
            position: 'relative',
            maxHeight: '440px',
            height: '440px',
            width: '100%',
            maxWidth: '90vw',
            margin: 'auto'
        }}>
            <Seo title={t('seo:seo_book.book.title')} description={t('seo:seo_book:book.description')}/>

            <BookProgress
                setSelectedPackage={setSelectedPackage}
                index={step}
                maxStep={maxStep}
                prevStep={handlePrevStep}
                request={request}
                onHandleStep={handleOnHandleStep}
                t={t}/>
            <div className="simple-trans-main">
                {transitions.map(({item, props, key}) => {
                    const Page = pages[item]
                    return <Page
                        key={key}
                        style={props}
                        onHandleStep={handleOnHandleStep}
                        place={place}
                        request={request}/>
                })}
            </div>
        </div>
    )
}

export default Book;
