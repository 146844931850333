import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core";
import { NavLink } from 'react-router-dom';

import { Text } from '../ui-kit/Typo'
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: theme.palette.background.contrast,
  },
}));

const LocationsFooter = () => {
  const [locations, setLocations] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    axios.get(process.env.REACT_APP_BUDDY_SERVICE + `/explore/locations`)
      .then((response) => {
        setLocations(response.data);
      })
  }, []);

  return (
    <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item xs={12}>
        <Divider class={classes.divider} />
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item>
            <Text type="title3" className="sb-blue1 bold capitalize">
              România
            </Text>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
          {locations.map((location, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Text type="subtitle3" className="sb-red1 capitalize light">
                <NavLink to={l => `/${l.pathname.split('/')[1]}/explore/${location.link}`}>
                    {location.name} - {location.county.name}
                </NavLink>
              </Text>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider class={classes.divider} />
      </Grid>
    </Grid>
  );
};

export default LocationsFooter;
