import React, { useState, useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { useParams } from "react-router-dom";
import axios from 'axios'
import Grid from '@material-ui/core/Grid';

import OtpAuth from './OtpAuth';
import Events from './events/Events';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EventEditPage(props){

  let query = useQuery();
  let { placeLink, token, lang } = useParams();
  const [place, setPlace] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink)
      .then(response => setPlace(response.data),
        err => {
          setPlace(err.response.data)
        });
  }, [token])

  return (
    <Grid container>

        {place && <Route path={`/:lang(en|ro)/iframe/:placeLink/event/edit`} exact render={() => (<OtpAuth email={query.get("email")} otp={query.get("otp")} place={place} />)} />}
        {place && <Route path={`/:lang(en|ro)/iframe/:placeLink/event/edit/events`} exact render={() => (<Events place={place} />)} />}
        

    </Grid>
  )
}
