import React from 'react'
import {Grid, Button} from '@material-ui/core'
import {Text} from '../../../components/ui-kit/Typo'
import {makeStyles} from '@material-ui/core/styles'
import {NavLink, useParams} from 'react-router-dom'
import JsonSchema from '../../../components/general/JsonSchema'


import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    },
    btn: {
        margin: 'auto 0',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        },
    }
}));

export default function PkgCamp({place}) {
    let {lang} = useParams();

    const {t} = useTranslation('listing');
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <JsonSchema packages={place.placePakage.filter((obj) => {
                return obj.pkgType === 'CAMP';
            })} place={place} lang={lang}/>
            <Grid item xs={12}>
                <Text type="subtitle2" className="sb-red1" bold>
                    <span style={{cursor: 'pointer'}}>{t('CAMP')}</span>
                </Text>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {place.placePakage.filter((obj) => {
                        return obj.pkgType === 'CAMP';
                    }).map((pkg, key) => {
                        return (
                            <Grid item xs={12}>
                                <Grid container className={classes.root} spacing={3}>
                                    <Grid item xs={12} md={10} key={key}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={10} md={10}>
                                                <NavLink to={location => `/${lang}/${place.link}/${pkg.link}`}
                                                         title={pkg.title}>
                                                    <Text type="subtitle3" component="h3" semibold className="sb-blue2">
                                                        {pkg.title}
                                                    </Text>
                                                </NavLink>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Text type="body1" component="h4" className="sb-text body1">
                                                    {pkg.type.length === 2 ? t('profile.pakage.camp_both') : pkg.type.indexOf('snowboard') > 0 ? t('profile.pakage.camp_snow') : t('profile.pakage.camp_ski')}
                                                    {pkg.availability} {pkg.availability === 1 ? t('profile.pakage.lesson') : t('profile.pakage.lessons')}
                                                    {pkg.timeUnit} {pkg.timeUnit === 1 ? t('profile.pakage.hour') : t('profile.pakage.hours')}
                                                    <br/>
                                                    {(pkg.price > 0 &&
                                                        <>
                                                            {t('profile.pakage.price')} <span
                                                            className="sb-text regular">{pkg.price} {place.placeInfo.currency}{pkg.pkgPrice ? t('profile.pakage.price_pkg') : t('profile.pakage.price_pers')}</span>
                                                        </>
                                                    )}
                                                </Text>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Text type="body" component="p">{pkg.abs && `"`}{pkg.abs}</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={'auto'} md={2} className={classes.btn}>
                                        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                                            <Grid>
                                                <NavLink to={location => `/${lang}/${place.link}/${pkg.link}`}
                                                         title={pkg.title}
                                                         aria-label={t('profile.pakage.details_more', {title: pkg.title})}>
                                                    <Button variant="outlined" color="primary">
                                                        {t('profile.pakage.details')}
                                                    </Button>
                                                </NavLink>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

// {pkg.info && <div dangerouslySetInnerHTML={{__html: convertToHTML(convertFromRaw(JSON.parse(pkg.info)))}} />}
