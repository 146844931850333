import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Text } from '../../components/ui-kit/Typo'
import { useTranslation } from 'react-i18next';

export default function PackageLoading(props){

  const { t } = useTranslation('package')

  return (
    <Grid container spacing={3} justify="space-around" alignItems="center">

      <Grid item md={4}>
        <Text type="subtitle" uppercase>{t('get.title')}</Text>
      </Grid>

      <Grid item md={8} xs={12}>
        <Grid container spacing={3} justify="space-around" alignItems="center">
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
    </Grid>
  )
}
