import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'

const getClass = type => {
    switch(type) {
      case 'title':
        return 'h1';
      case 'title2':
        return 'h2';
      case 'subtitle':
        return 'h3';
      case 'subtitle2':
        return 'h4';
      case 'title3':
        return 'h5';
      case 'subtitle3':
        return 'h6';
      case 'body':
        return 'body1';
      case 'span':
        return 'span';
      default:
        return 'body2';
    }
}

export function Text(props){
  let { type, color, component, bold, semibold, align, className, style, uppercase } = props;
  const typeClass = getClass(type);

  return(
    <Typography variant={ typeClass }
      gutterBottom
      className={`sb-text ${type} ${className}`}
      style={{...style, fontWeight: bold ? '800' : semibold ? '600' : '', textTransform: uppercase ? 'uppercase' : ''}}
      component={component}
      color={color}>
      { props.children }

    </Typography>)
};

Text.propTypes = {
  type: PropTypes.oneOf(['title', 'title2', 'subtitle', 'subtitle2', 'title3', 'subtitle3', 'body', 'span']),
  className: PropTypes.string,
  component: PropTypes.string,          // ce element va fi randat in html
  color: PropTypes.string,
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  uppercase: PropTypes.bool,
  align: PropTypes.string,
  style: PropTypes.object
}

Text.defaultProps = {
  type: 'p',
  bold: false,
  uppercase: false,
  semibold: false
};
