import React from 'react'
import {animated} from 'react-spring'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {Text} from '../../../components/ui-kit/Typo'
import 'date-fns';
import {useTranslation} from 'react-i18next'
import Seo from "../../../components/general/Seo";

export const Step12 = ({style, onHandleStep}) => {

    const {t} = useTranslation(['iframe', 'seo']);

    return (
        <animated.div style={{...style, padding: '16px'}}>
            <Seo title={t('seo:seo_book.step12.title')} description={t('seo:seo_book.step12.description')}/>

            <Grid container spacing={3} justify="space-around" alignItems="center">

                <Grid item md={4}>
                    <Text type="subtitle" uppercase>{t('event.book.step10.multiple_reservations')}</Text>
                </Grid>

                <Grid item md={8}>
                    <Grid container spacing={3} justify="space-around" alignItems="center">

                        <Grid item xs={12}>
                            <Text type="subtitle2"><Text type="span" color="primary"
                                                         semibold>{t('event.book.step10.atention')}</Text> {t('event.book.step10.confirmation')}
                            </Text>
                            <Text type="body">{t('event.book.step10.btn_click')}<Text type="span" color="primary"
                                                                                      semibold>{t('event.book.step10.validation')}</Text> {t('event.book.step10.email')}
                            </Text>
                            <Text type="body">{t('event.book.step10.automatically_deleted')}</Text>
                            <Text>{t('event.book.step10.ps')}</Text>
                        </Grid>

                        <Grid item xs={12}>
                            <Text type="subtitle3">{t('event.book.step10.hashtag')}</Text>
                        </Grid>

                        <Grid item xs={12} className={"text-center"}>
                            <Button variant="outlined" color="secondary" onClick={() => onHandleStep()}>
                                {t('event.book.step10.new_reservation')}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </animated.div>
    )
}
