import React, { useState, useEffect } from 'react'
import moment from 'moment';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { Text } from '../../../components/ui-kit/Typo'

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Terms from '../../event/book/terms/Terms'

const DATE_FORMAT = 'DD.MM.YYYY';

export default function CampForm(props){

  const { t } = useTranslation('iframe');
  let { placeLink } = useParams();
  let { place, pakage, eventPackages, error, setError, setSuccess } = props;

  const [clientPackage, setClientPackage] = useState({eventPackage: eventPackages[0], client: {}, info: pakage.textArea});
  const [check, setCheck] = useState({ terms: false, agreement: false, form: false });
  const [loading, setLoading] = useState(false);
  const [errcount, setErrcount] = useState(0);

  useEffect(() => {
    setClientPackage({...clientPackage, eventPackage: eventPackages && eventPackages.length > 0 ? eventPackages[0] : ''})
  }, [eventPackages])

  const handleChange = event => {
    setCheck({ ...check, [event.target.value]: event.target.checked });
  };

  useEffect(() => {
    let valid = true;
    if (clientPackage.name === undefined || (clientPackage.name.length < 1 || clientPackage.name.length > 254))
      valid = false;
    if (clientPackage.email === undefined || (clientPackage.email.length < 1 || clientPackage.email.length > 254))
      valid = false;
    if (clientPackage.phone === undefined || (clientPackage.phone.length < 1 || clientPackage.phone.length > 254))
      valid = false;
    setCheck({...check, form: valid});
  }, [clientPackage])

  const submitRequest = () => {
    setLoading(true);
    // setSuccess(true);
    // setError({});
    // setClientPackage({eventPackage: eventPackages[0], client: {}, info: pakage.textArea})
    axios.post(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + placeLink + "/package/" + pakage.link, {...clientPackage, eventPackage: {...clientPackage.eventPackage, pakage: null}})
    .then(res => {
      // setClientPackage({eventPackage: eventPackages[0], client: {}, info: pakage.textArea})
      setLoading(false);
      setSuccess(true);
      // setError({});
    },
    err => {
      setLoading(false);
      setErrcount(errcount + 1);
      setError({submitRequest: true, submitRequestMsg: err.response.data.error});
    });
  }

  const termsLabel = (<span> <span>{t('event.book.step8.terms_label1')} </span> <a href={`${place.placeInfo.terms}`} target="_blank" >{t('event.book.step8.terms_label2')}</a> </span>)
  const agreementLabel = (<Terms
    place={place}
    request={clientPackage}
    accept={() => setCheck({ ...check, agreement: true })} />)

  return (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Text type="subtitle2">
            {t('camp.book.register')} {pakage.title}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="evtPkg">Perioada</FormLabel>
            <RadioGroup aria-labelledby="evtPkg" name="evtPkg">
              {eventPackages.map((evt) => {
                return (
                    <FormControlLabel value={clientPackage.eventPackage} label={`${moment(evt.start).format(DATE_FORMAT)} - ${moment(evt.end).format(DATE_FORMAT)} | ${evt.title}`}
                     control={<Radio checked={clientPackage.eventPackage === evt} onChange={() => setClientPackage({...clientPackage, eventPackage: evt})} />} />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="name"
                autocomplete="name"
                label={t('event.book.step7.name')}
                onChange = {(event, newValue) => {
                  var newVal = event.target.value;
                  if (newVal.length < 54)
                    setClientPackage({...clientPackage, client: {...clientPackage.client, name:newVal}})
                }}
                value={clientPackage.client.name}
                error={error.name}
                helperText={error.name ? `${t('event.book.step7.name_helperText')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="email"
                type="email"
                autocomplete="email"
                label={t('event.book.step7.email')}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setClientPackage({...clientPackage, client: {...clientPackage.client, email:newVal}})
                }}
                value={clientPackage.client.email}
                error={error.email}
                helperText={error.email ? `${t('event.book.step7.email_helperText')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="tel"
                type="tel"
                autocomplete="phone"
                label={t('event.book.step7.phone')}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setClientPackage({...clientPackage, client: {...clientPackage.client, phone:newVal}})
                }}
                value={clientPackage.client.phone}
                error={error.phone}
                helperText={error.phone ? `${t('event.book.step7.phone_helperText')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" fullWidth>
              <InputLabel id="level">Nivel</InputLabel>
              <Select
                labelId="level"
                id="level-s"
                fullWidth
                value={clientPackage.level}
                onChange={(event, newValue) => {
                  var newVal = event.target.value;
                  setClientPackage({...clientPackage, level: newVal})
                }}
                label="Nivel"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem key={'BEGINNER'} value={'BEGINNER'}>Incepator</MenuItem>
                <MenuItem key={'INTERMEDIATE'} value={'INTERMEDIATE'}>Intermediar</MenuItem>
                <MenuItem key={'ADVANCED'} value={'ADVANCED'}>Avansat</MenuItem>
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Informatii suplimentare:"
                placeholder={t('event.book.step7.remarks')}
                fullWidth
                multiline
                rows="5"
                onChange = {(event, newValue) => {
                  var newVal = event.target.value;
                  setClientPackage({...clientPackage, info: newVal})
                }}
                value={clientPackage.info}
                error={error.info}
                helperText={error.info ? `${t('event.book.step7.remarks_helperText')}` : ''}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={"text-center margin-auto"}>
          <Grid container spacing={1}>
            <Grid item>
              <Checkbox
                checked={check.terms}
                onChange={handleChange}
                value="terms"
                color="secondary"
              />
              {termsLabel}
            </Grid>
            <Grid item>
              <Checkbox
                checked={check.agreement}
                onChange={handleChange}
                value="agreement"
                color="secondary"
              />
              {agreementLabel}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {loading ?
            <CircularProgress color="secondary" /> :
            <div>{errcount > 3 ? '' :
              <div>
                  <Button variant="outlined" color="primary" disabled={!check.terms || !check.agreement || !check.form} onClick={submitRequest}>
                    {t('event.book.step8.confirmation')}
                  </Button>
              </div>}
            </div>}
          {error.submitRequest ?
            <Text type="body" color="error">{t('camp.book.error')} <br/>{error.submitRequestMsg}</Text> : ''}
        </Grid>
      </Grid>
    </form>

  )
}
