import React from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'

export default function JsonSchema(props) {
  let { packages, place, lang } = props;
  const { t } = useTranslation('listing');

  const data = {
      "@context": "http://schema.org",
      "@type": "Service",
      "provider": {
        "@type": "Organization",
        "name": place?.name || "",
        "url": "https://snowbuddy.app/"+lang+"/"+place?.link || ""
      },
      "serviceType": [],
      "offers":[]
    };

    if (packages && packages.length > 0) {
        // Iterăm prin fiecare pachet
        packages.map((packageItem, index) => {
          const { abs, availability, link, locations, name, pkgType, price, spots, title, type } = packageItem;
          const description = `${packageItem.type.length === 2 ? t('profile.pakage.camp_both') : packageItem.type.indexOf('snowboard') > 0 ? t('profile.pakage.camp_snow') : t('profile.pakage.camp_ski')}
                               ${packageItem.availability} ${packageItem.availability === 1 ? t('profile.pakage.lesson') : t('profile.pakage.lessons')}
                               ${packageItem.timeUnit} ${packageItem.timeUnit === 1 ? t('profile.pakage.hour') : t('profile.pakage.hours')}`;
          const areaServed = [];

          data.serviceType.push(...type);

          // Iterăm prin fiecare locație din pachet și adăugăm informațiile în array-ul de locații
          locations.forEach(location => {
            areaServed.push({
              "@type": "City",
              "name": location.name,
            });
          });

          const offer = {
              "@type": "Offer",
              "availability": availability,
              "url": "https://snowbuddy.app/" + lang + "/" + link,
              "category": pkgType,
              "name": title,
              "itemOffered": {
                "@type": "Service",
                "name": name,
                "category": type,
                "provider": {
                  "@type": "Organization",
                  "name": place?.name || "",
                  "url": "https://snowbuddy.app/" + lang + "/" + place?.link || ""
                }
              }
            };

            offer.areaServed = areaServed;

            if(abs !== null){
                offer.itemOffered.description = abs;
            } else {
                offer.itemOffered.description = description
            }

            if (price !== 0) {
              offer.price = price;
            }

            data.offers.push(offer);
        });
      } else {
//         console.log("Nu există pachete disponibile.");
      }

      return(
        <script type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}>
        </script>
      )
}
