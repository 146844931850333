import React from 'react';
import Grid from "@material-ui/core/Grid";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Text } from '../ui-kit/Typo'

const MenuFooter = () => {

  const { t } = useTranslation('general');

  return (
    <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item xs={12} md={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-end">
          <Grid item>
            <Text type="subtitle3" className="sb-blue1 regular capitalize">
              Links:
            </Text>
          </Grid>
          <Grid item>
            <Text type="subtitle3" className="light">
              <NavLink to={location => `/${location.pathname.split('/')[1]}/about`}>
                  {t('nav.items.about')}
              </NavLink>
            </Text>
          </Grid>
          <Grid item>
            <Text type="subtitle3" className="light">
              <a href="https://snowbuddy.app/app/">
                Log In
              </a>
            </Text>
          </Grid>
          <Grid item xs={6} md ={3}>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenuFooter;
