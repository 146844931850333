import React from 'react'
import '../../../iframe/event/book/assets/style.scss'
import Grid from '@material-ui/core/Grid';
import {Text} from '../../../components/ui-kit/Typo'

import {useTranslation} from 'react-i18next'

export function BookProgress(props) {

    const {t} = useTranslation('iframe');
    let {setSelectedPackage, index, prevStep, maxStep, request, onHandleStep} = props;

    const handleBackClick = () => {
        if (index !== 2) {
            prevStep();
        } else {
            setSelectedPackage(false);
        }
    };

    return (
        <Grid item xs={12} style={{padding: '18px'}}>
            <Text type="body">
        <span style={{cursor: 'pointer'}}
              onClick={handleBackClick}
              className={index === 1 ? 'disabled' : ''}>
          {`<`} {t('event.book.progress.back')}
        </span>
                {request.pakage.title && ` | ${t('event.book.progress.package')} ${request.pakage.title}`}
            </Text>
        </Grid>
    )
}