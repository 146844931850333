import React, { useEffect, useState } from 'react';

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"
import { Button } from '@material-ui/core';

import '../assets/style/style.scss'
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// static pages
import HomePage from '../pages/home/HomePage'
import AboutPage from '../pages/diverse/AboutPage'
import GetABookPage from './diverse/GetABookPage';
import Components from '../components/Components'
import TermsPage from '../components/general/TermsPage'
import Copyright from '../components/general/Copyright'
import Page404 from '../components/general/404'
import ComingSoonPage from './diverse/ComingSoon'
import NavBar from '../components/header/NavBar'
import LocationsFooter from '../components/footer/LocationsFooter'
import MenuFooter from '../components/footer/MenuFooter'

// dinamic pages
import Place from './listing/profile/Place'
import ExplorePage from './explore/ExplorePage'
import Locations from './listing/list/Locations';
import PackagePage from '../iframe/package/PackagePage'

//ROUTING
import { Switch as RoutingSwitch, Route } from 'react-router-dom'

//CONTEXT
import { AccessProvider } from '../components/context/accessState'


function Face({ t, toggleDarkMode, darkMode }) {

  const userPrefDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [searchDto, setSearchDto] = useState({
    eventType: [],
    locations: [],
    pkgType: []});

  useEffect(() => {
    toggleDarkMode(userPrefDarkMode)
  }, [userPrefDarkMode])

  return (
    <AccessProvider>
      <div className="bg">
        <div className="bg-logo">
          <Container maxWidth="lg" style={{ minHeight: '84vh'}}>
            <NavBar toggleDarkMode={() => toggleDarkMode()} darkMode={darkMode}/>
            <RoutingSwitch>
              <Route path='/:lang(en|ro|es)/'>
                <RoutingSwitch>
                  <Route path={`/:lang/`} exact> <HomePage searchDto={searchDto}/></Route>
                  <Route exact path='/:lang/about' exact component={AboutPage} />
                  <Route exact path='/:lang/getabook' exact component={GetABookPage} />
                  <Route exact path='/:lang/terms' exact component={TermsPage} />
                  <Route exact path='/:lang/package' exact component={PackagePage} />
                  <Route exact path='/:lang/baking' component={ComingSoonPage} />
                  <Route exact path='/:lang/components' component={Components} />
                  <Route exact path={`/:lang/404`} component={Page404} />
                  <Route exact path={`/:lang(en|ro|es)/explore`} component={ExplorePage} />
                  <Route exact path={`/:lang(en|ro|es)/explore/:location`} component={ExplorePage} />
                  <Route exact path={`/:lang(en|ro|es)/:placeLink`} component={Place} />
                  <Route exact path={`/:lang(en|ro|es)/:placeLink/*`} component={Place} />
                  <Route path="/:lang/*" component={Page404} />
                </RoutingSwitch>
              </Route>
              <Route path="*" render={(props) => { props.history.replace({ pathname: `/ro${props.history.location.pathname}` }) }} />
            </RoutingSwitch>
          </Container>
          <Container maxWidth="lg">
            <LocationsFooter />
            <MenuFooter />
            <Copyright />
          </Container>
        </div>
      </div>
    </AccessProvider >
  );
}

export default Face;
