import React from 'react'
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../components/ui-kit/Typo'

import { useTranslation } from 'react-i18next'

export default function StepError(props){

  let { place } = props;

  const { t } = useTranslation('iframe');

  return (
    <Grid container spacing={3} justify="space-around" alignItems="center">

      <Grid item md={12}></Grid>
      <Grid item md={4}>
        <Text type="subtitle" uppercase>{t('event.book.step_no_req.online_reservation')}</Text>
      </Grid>

      <Grid item md={8} xs={12}>
        <Grid container spacing={3} justify="space-around" alignItems="center">

            <Grid item xs={12}>
              <Text type="subtitle2"><Text color="primary" semibold type="span">Ups!</Text> {t('event.book.step_no_req.something_went_wrong')}</Text>
              <Text type="subtitle3">{t('event.book.step_no_req.contact_msg_tel')} <a href={`tel:${place.placeInfo.phone}`}>{place.placeInfo.phone}</a> {t('event.book.step_no_req.contact_msg_web')} <a href={`${place.placeInfo.website}`}>{place.placeInfo.website}</a></Text>
            </Grid>

          <Grid item xs={12}>
            <Text type="subtitle3">{t('event.book.step_no_req.hashtag')}</Text>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}
