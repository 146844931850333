import React, { useState, useEffect } from 'react'

import { themeObject } from '../../assets/style/theme'
import { useLocation } from 'react-router-dom'

export const useDarkMode = () => {

  const [theme, setTheme] = useState(themeObject(false))
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    doToggleDarkMode();
  }, [darkMode])

  const toggleDarkMode = dark => {
    if (dark !== undefined)
      setDarkMode(dark)
    else
      setDarkMode(!darkMode)
  }

  const doToggleDarkMode = () => {
    const updatedTheme = {
      ...theme,
      palette: {
        ...theme.palette,
        type: darkMode ? 'dark' : 'light',
        ...(darkMode
        ? {
            text: {
              primary: '#fff',
              secondary: '#fefefe',
            },
            background: {
              default: '#303030',
              contrast: '#777'
            },
            divider: {
              primary: '#777'
            }
          }
        : {
            text: {
              primary: '#555',
              secondary: '#777',
            },
            background: {
              default: '#fff',
              contrast: '#fefefe'
            },
            divider: {
              primary: '#fefefe'
            }
          }),
      }
    }
    setTheme(updatedTheme)
  }

  return [theme, toggleDarkMode, darkMode]
}
