import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {useTranslation} from 'react-i18next'

import CampForm from '../../../iframe/camp/book/CampForm'
import SubmitSuccess from '../../../iframe/camp/book/SubmitSuccess';
import StepNoRequest from '../../../iframe/event/book/StepNoRequest';
import StepError from '../../../iframe/event/book/StepError';
import Seo from "../../../components/general/Seo";

export default function CampBookPage(props) {

    const {t} = useTranslation('iframe');
    let {place, pakage} = props;

    const [eventPackages, setEventPackages] = useState([]);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BUDDY_SERVICE + '/place/' + place.link + "/package/" + pakage.link)
            .then(res => {
                    setEventPackages(res.data);
                },
                err => {
                    setError({eventPackagesNotFound: true});
                });
    }, [pakage]);

    return (
        <div>
            <Seo title={`${pakage.title} - ${place.name}`}
                 description={`Book your spot for ${pakage.title} at ${place.name}. Explore a variety of activities and enjoy an unforgettable experience.`}/>

            {place ?
                <div>
                    {place.placeInfo.publicRequest && pakage && eventPackages.length > 0 ?
                        <div>
                            {success ?
                                <SubmitSuccess setSuccess={setSuccess}/> :
                                <CampForm
                                    place={place}
                                    pakage={pakage}
                                    eventPackages={eventPackages}
                                    error={error}
                                    setError={setError}
                                    setSuccess={setSuccess}/>}
                        </div> :
                        <StepNoRequest
                            place={place}/>}
                </div> :
                <StepError/>
            }
        </div>
    )
}
