import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip } from "@mui/material";

import { ReactComponent as ThemeIcon } from './icon/theme.svg';
import { ReactComponent as LanguageIcon } from './icon/language.svg';

import { Button } from '@material-ui/core'
import { Grid, Box } from '@material-ui/core'

import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import FilterBar from "./filter/FilterBar";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '30px',
    height: '30px',
    fill: theme.palette.text.secondary,
  },
  web: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    [theme.breakpoints.only('md')]: {
      display: 'block'
    }
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default function NavBar(props) {

  const classes = useStyles();
  const { t, i18n } = useTranslation('general');
  const location = useLocation();
  const history = useHistory();

  const changeLanguage = lng => {
    const pathname = location.pathname;
    const newPathname = pathname.replace(/[/][a-z]{2}[/]/g, `/${lng}/`);
    history.replace(newPathname)
    i18n.changeLanguage(lng);
  };

  return (
      <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center" style={{margin: '30px 0'}}>
        <Grid item md={2} sm={6}>
          <NavLink to={location => `/${location.pathname.split('/')[1]}/`}>
            <img src={require('../../assets/images/logo.png')} style={{ width: '50px' }} />
          </NavLink>
        </Grid>
        <Box item md={8} sm={12} display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
          {false && <FilterBar />}
        </Box>
        <Grid item md={2} sm={6} className="text-right">
          <Tooltip title={props.darkMode ? t('general:nav.items.dark') : t('general:nav.items.light')}>
            <Button onClick={() => props.toggleDarkMode()} id="theme-switch" className={classes.btn}>
              <ThemeIcon className={classes.icon} />
            </Button>
          </Tooltip>
          <Tooltip title={location.pathname.split('/')[1] === 'ro' ? t('general:nav.items.en') : location.pathname.split('/')[1] === 'en' ? t('general:nav.items.es') : t('general:nav.items.ro')}>
            <Button onClick={() => location.pathname.split('/')[1] === 'ro' ? changeLanguage('en') : location.pathname.split('/')[1] === 'en' ? changeLanguage('es') : changeLanguage('ro')}>
              <LanguageIcon className={classes.icon} />
            </Button>
          </Tooltip>
        </Grid>
        <Box item md={8} xs={12} sm={12} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'none' }}>
          <FilterBar />
        </Box>
      </Grid>
  );
}
    // <Navbar expand="lg">
    //
    //   <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //   <Navbar.Brand>
    //     <NavLink to={location => `/${location.pathname.split('/')[1]}/`}>
    //       <img src={require('../../assets/images/logo.png')} style={{ width: '50px' }} />
    //     </NavLink>
    //   </Navbar.Brand>
    //   {/* <Search /> */}
    //   <FilterBar
    //       searchDto={props.searchDto}
    //       setSearchDto={props.setSearchDto}
    //   />
    //
    //   <Navbar.Collapse id="basic-navbar-nav">
    //     <Nav className="ml-auto">
    //       <NavDropdown title={<TranslateIcon className={classes.icon} />} id="language-switch" drop="down">
    //         <NavDropdown.Item onClick={() => changeLanguage('ro')}>
    //             {t('nav.language.ro')}
    //         </NavDropdown.Item>
    //         <NavDropdown.Item onClick={() => changeLanguage('en')}>
    //             {t('nav.language.en')}
    //         </NavDropdown.Item>
    //       </NavDropdown>
    //       <Button onClick={() => props.toggleDarkMode()} id="theme-switch" className={classes.btn}>
    //         <Brightness4Icon className={classes.icon} />
    //       </Button>
    //     </Nav>
    //   </Navbar.Collapse>
    //
    // </Navbar>
