import React, { useState, createContext } from 'react'

export const AccessContext = createContext();

export const AccessProvider = props => {

  const [hasAccess, setHasAccess] = useState(
    {
      hasAccess: false
    }
  );

  return (
    <AccessContext.Provider value={[hasAccess, setHasAccess]}>
      {props.children}
    </AccessContext.Provider>
  )
};
