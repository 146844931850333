import React, {useEffect, useState} from 'react';

import { Grid, Box } from '@material-ui/core'
import { ReactComponent as SkiIcon } from './icon/ski.svg';
import { ReactComponent as SnowboardIcon } from './icon/snowboard.svg';
import { ReactComponent as KidIcon } from './icon/kids.svg';
import { ReactComponent as CourseIcon } from './icon/course.svg';
import { ReactComponent as SimpleIcon } from './icon/simple.svg';
import { ReactComponent as CampIcon } from './icon/camp.svg';
import { ReactComponent as ExperienceIcon } from './icon/experience.svg';
import { Button, makeStyles } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {NavLink, useHistory, useParams} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  icon:{
      fill: theme.palette.text.secondary,
      width: 35,
      height: 35
  },
  activeIcon: {
    fill: theme.palette.primary.main,
    width: 35,
    height: 35
  }
}));

function FilterBar(props) {
    const { t } = useTranslation('general');
    const classes = useStyles();
    const history = useHistory();
    const { lang, type, pgkType } = useParams();
    const [searchDto, setSearchDto] = useState({
            eventType: [],
            locations: [],
            pkgType: [],
        }
    );

    const searchDtoToPath = (dto) => {
        let path = '';
        if(dto.eventType.length === 0 && dto.pkgType.length === 0)
            path = '/ro/explore';
        else if(dto.eventType.length === 0 && dto.pkgType.length !== 0)
            path = `/ro/explore/${dto.pkgType.map(pkg => pkg.toLowerCase()).join('+')}`;
        else if(dto.eventType.length !== 0 && dto.pkgType.length === 0)
            path = `/ro/explore/${dto.eventType.join('+')}`;
        else
            path = `/ro/explore/${dto.eventType.join('+')}/${dto.pkgType.map(pkg => pkg.toLowerCase()).join('+')}`;
        setSearchDto(dto);
        return path;
    }

    const eventTypeButtons = (buttonLabel) => {
        const eventTypeClone = searchDto.eventType.includes(buttonLabel)
            ? searchDto.eventType.filter(label => label !== buttonLabel)
            : [...searchDto.eventType, buttonLabel];
        let redirect = searchDtoToPath({ ...searchDto, eventType: eventTypeClone });
        history.push(redirect);
    };

    const packageTypeButtons = (buttonLabel) => {
        const pkgTypeClone = searchDto.pkgType.includes(buttonLabel)
            ? searchDto.pkgType.filter(label => label !== buttonLabel)
            : [...searchDto.pkgType, buttonLabel];
        let redirect = searchDtoToPath({ ...searchDto, pkgType: pkgTypeClone });
        history.push(redirect);
    };

    return (
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item sm={4} xs={4} md className="text-center">
            <Tooltip title={t('general:nav.items.ski')}>
                <Button
                    onClick={() => eventTypeButtons('ski')} color={searchDto.eventType.includes('ski') ? "primary" : "default"}>
                    <SkiIcon className={searchDto.eventType.includes('ski') ? classes.activeIcon : classes.icon}/>
                </Button>
            </Tooltip>
          </Grid>
          <Grid item sm={4} xs={4} md className="text-center">
            <Tooltip title="Snowboard">
                <Button
                    onClick={() => eventTypeButtons('snowboard')} color={searchDto.eventType.includes('snowboard') ? "primary" : "default"}>
                    <SnowboardIcon className={searchDto.eventType.includes('snowboard') ? classes.activeIcon : classes.icon}/>
                </Button>
            </Tooltip>
          </Grid>
          <Grid item sm={4} xs={4} md className="text-center">
            <Tooltip title={t('general:nav.items.kids')}>
                <Button
                    onClick={()=> eventTypeButtons('kids')} color={searchDto.eventType.includes('kids') ? "primary" : "default"}>
                    <KidIcon className={searchDto.eventType.includes('kids') ? classes.activeIcon : classes.icon}/>
                </Button>
            </Tooltip>
          </Grid>
          <Box item display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>|</Box>
          <Grid item sm={3} xs={3} md className="text-center">
            <Tooltip title={t('general:nav.items.simples')}>
                    <Button
                        onClick={()=> packageTypeButtons('SIMPLE')} color={searchDto.pkgType.includes('SIMPLE') ? "primary" : "default"}>
                        <SimpleIcon className={searchDto.pkgType.includes('SIMPLE') ? classes.activeIcon : classes.icon}/>
                    </Button>
            </Tooltip>
          </Grid>
          <Grid item sm={3} xs={3} md className="text-center">
            <Tooltip title={t('general:nav.items.courses')}>
                <Button
                    onClick={()=> packageTypeButtons('COURSE')} color={searchDto.pkgType.includes('COURSE') ? "primary" : "default"}>
                    <CourseIcon className={searchDto.pkgType.includes('COURSE') ? classes.activeIcon : classes.icon}/>
                </Button>
            </Tooltip>
          </Grid>
          <Grid item sm={3} xs={3} md className="text-center">
            <Tooltip title={t('general:nav.items.camps')}>
                <Button
                    onClick={()=> packageTypeButtons('CAMP')} color={searchDto.pkgType.includes('CAMP') ? "primary" : "default"}>
                    <CampIcon className={searchDto.pkgType.includes('CAMP') ? classes.activeIcon : classes.icon}/>
                </Button>
            </Tooltip>
          </Grid>
          <Grid item sm={3} xs={3} md className="text-center">
            <Tooltip title={t('general:nav.items.experiences')}>
                    <Button
                        onClick={()=> packageTypeButtons('EXPERIENCE')} color={searchDto.pkgType.includes('EXPERIENCE') ? "primary" : "default"}>
                        <ExperienceIcon className={searchDto.pkgType.includes('EXPERIENCE') ? classes.activeIcon : classes.icon}/>
                    </Button>
            </Tooltip>
          </Grid>
          {/*<div className={classes.buttonContainer}>*/}
          {/*    <SearchLocation setLoc={setLoc} ></SearchLocation>*/}
          {/*</div>*/}
        </Grid>
    );
}

export default FilterBar;
