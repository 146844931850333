import React, {useState} from 'react'
import {animated} from 'react-spring'

import Grid from '@material-ui/core/Grid';
import {Papir} from '../../../components/ui-kit/Papir'
import {Text} from '../../../components/ui-kit/Typo'
import Button from '@material-ui/core/Button';
import "react-datepicker/dist/react-datepicker.css";

import {ReactComponent as OneIcon} from './assets/4_man.svg'
import {ReactComponent as TwoIcon} from './assets/4_men.svg'
import {ReactComponent as TreeIcon} from './assets/4_group.svg'
import {ReactComponent as MoreIcon} from './assets/4_more.svg'
import {ReactComponent as PlusIcon} from './assets/plus.svg'
import {ReactComponent as MinusIcon} from './assets/minus.svg'

import {makeStyles} from '@material-ui/core/styles'

import {useTranslation} from 'react-i18next'
import Seo from "../../../components/general/Seo";

const useStyles = makeStyles((theme) => ({
    svgicon: {
        width: '90px',
        height: '90px',
        marginBottom: '10px',
        fill: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            width: '75px',
            height: '75px'
        },
        "& path": {
            fill: theme.palette.text.secondary,
        }
    },
    active: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        "& path": {
            fill: theme.palette.primary.main,
        }
    }
}));

export function Step4(props) {

    let {style, onHandleStep, place, request} = props;
    const classes = useStyles();

    const {t} = useTranslation(['iframe', 'seo']);
    
    const packageTitle = request.pakage ? request.pakage.title : 'Default Title';

    const [attendees, setAttendees] = useState(parseInt(request.attendees));

    return (
        <animated.div style={{...style, padding: '16px'}}>
            <Seo title={`${t('general:nav.items.package')} ${packageTitle} - ${t('seo:seo_book.step4.title')}`}
                 description={t('seo:seo_book.step4.description')}/>

            <Grid container
                  spacing={3}
                  justify="space-around"
                  alignItems="center">
                <Grid item md={4}>
                    <Text type="subtitle" uppercase>{t('event.book.step4.title')}</Text>
                </Grid>
                <Grid item md={8} xs={12}>
                    {attendees < 4 ?
                        <Grid container
                              spacing={1}
                              direction="row"
                              justify="space-around"
                              alignItems="center"
                        >
                            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('1')} xs={6}>
                                <Papir align="center">
                                    <OneIcon className={`${attendees === 1 && classes.active} ${classes.svgicon}`}/>
                                    <Text type="subtitle3">1 pers</Text>
                                </Papir>
                            </Grid>
                            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('2')} xs={6}>
                                <Papir align="center">
                                    <TwoIcon className={`${attendees === 2 && classes.active} ${classes.svgicon}`}/>
                                    <Text type="subtitle3">2 pers</Text>
                                </Papir>
                            </Grid>
                            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep('3')} xs={6}>
                                <Papir align="center">
                                    <TreeIcon className={`${attendees === 3 && classes.active} ${classes.svgicon}`}/>
                                    <Text type="subtitle3">3 pers</Text>
                                </Papir>
                            </Grid>
                            <Grid item style={{cursor: 'pointer'}} onClick={() => setAttendees(4)} xs={6}>
                                <Papir align="center">
                                    <MoreIcon className={`${attendees > 4 && classes.active} ${classes.svgicon}`}/>
                                    <Text type="subtitle3">{t('event.book.step4.more')}</Text>
                                </Papir>
                            </Grid>
                        </Grid> :
                        <Grid container
                              spacing={3}
                              direction="row"
                              justify="space-around"
                              alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Text type="subtitle2" uppercase>{attendees} {t('event.book.step4.persons')}</Text>
                            </Grid>
                            <Grid item style={{cursor: 'pointer'}}
                                  onClick={() => attendees < 8 ? setAttendees(attendees + 1) : onHandleStep(attendees)}
                                  xs={6}>
                                <Papir align="center">
                                    <PlusIcon className={`${classes.svgicon}`}/>
                                </Papir>
                            </Grid>
                            <Grid item style={{cursor: 'pointer'}} onClick={() => setAttendees(attendees - 1)} xs={6}>
                                <Papir align="center">
                                    <MinusIcon className={`${classes.svgicon}`}/>
                                </Papir>
                            </Grid>
                            <Grid item xs={12} className={"text-center"}>
                                <Button variant="outlined" color="primary" onClick={() => onHandleStep(attendees)}>
                                    Next >
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </animated.div>
    )
}

//https://www.flaticon.com/premium-icon/group_937135?related_id=937135&origin=pack
