import React from 'react';

import { ThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import './i18n/i18n';

// static pages
import IFramePage from './iframe/iFramePage';
import Face from './pages/Face';

import './assets/style/style.scss'
import CssBaseline from '@material-ui/core/CssBaseline';

//ROUTING
import { BrowserRouter as Router, Switch as RoutingSwitch, Route } from 'react-router-dom'

//HELMET
import { Helmet } from 'react-helmet'

//CONTEXT
import { useDarkMode } from './components/hooks/darkMode'

import ScrollToTop from './components/general/ScrollToTop'

function App() {

  const [theme, toggleDarkMode, darkMode] = useDarkMode();
  let themeObj = createMuiTheme(theme);
  themeObj = responsiveFontSizes(themeObj);

  return (
    <ThemeProvider theme={themeObj}>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>SnowBuddy | Ski and Snowboard school management system</title>
      </Helmet>
      <Router>
        <ScrollToTop />
        <RoutingSwitch>
            <Route path='/:lang(ro|en|es)/iframe/*' render={(props) => <IFramePage {...props} toggleDarkMode={toggleDarkMode} />}/>
            <Route path="/" render={(props) => <Face {...props} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />}/>
        </RoutingSwitch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
