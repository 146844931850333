import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Text } from '../../../components/ui-kit/Typo'

export default function Info({ info }) {

  return (
      <Grid container spacing={1}>
          <Grid item xs={12}>
            <Text type="body">
                {info.placeInfo.info}
            </Text>
          </Grid>
      </Grid>
  )
}
