import React, {useEffect} from 'react'
import {animated} from 'react-spring'

import Grid from '@material-ui/core/Grid';
import {Papir} from '../../../components/ui-kit/Papir'
import {Text} from '../../../components/ui-kit/Typo'

import {ReactComponent as Mountain0Icon} from './assets/4_0.svg'
import {ReactComponent as Mountain1Icon} from './assets/4_1.svg'
import {ReactComponent as Mountain2Icon} from './assets/4_2.svg'

import {makeStyles} from '@material-ui/core/styles'

import {useTranslation} from 'react-i18next'
import Seo from "../../../components/general/Seo";

const useStyles = makeStyles((theme) => ({
    svgicon: {
        width: '100px',
        height: '100px',
        fill: `${theme.palette.text.secondary} !important`,
        [theme.breakpoints.down('xs')]: {
            width: '75px',
            height: '75px'
        }
    },
    active: {
        color: theme.palette.primary.main,
        fill: `${theme.palette.primary.main} !important`,
    }
}));

export function Step3(props) {

    let {style, onHandleStep, request} = props;
    const classes = useStyles();

    const {t} = useTranslation(['iframe', 'seo']);

    const packageTitle = request.pakage ? request.pakage.title : 'Default Title';

    useEffect(() => {
        if (request.pakage.locations.length < 2)
            onHandleStep(undefined);
    }, [])

    return (
        <animated.div style={{...style, padding: '16px'}}>
            <Seo title={`${t('general:nav.items.package')} ${packageTitle} - ${t('seo:seo_book.step3.title')}`}
                 description={t('seo:seo_book.step3.description')}/>

            <Grid container
                  spacing={3}
                  justify="space-around"
                  alignItems="center">
                <Grid item md={4}>
                    <Text type="subtitle" uppercase>{t('event.book.step3.location')}</Text>
                </Grid>
                <Grid item md={8}>
                    <Grid container
                          spacing={1}
                          direction="row"
                          justify="space-around"
                          alignItems="center"
                    >
                        {request.pakage.locations.map((l, i) => (
                            <Grid item style={{cursor: 'pointer'}} onClick={() => onHandleStep(l.enumm)} md={4} xs={12}>
                                <Papir align="center">
                                    {(i % 3 === 0) && <Mountain0Icon
                                        className={`${request.location === l.link && classes.active} ${classes.svgicon}`}/>}
                                    {(i % 3 === 1) && <Mountain1Icon
                                        className={`${request.location === l.link && classes.active} ${classes.svgicon}`}/>}
                                    {(i % 3 === 2) && <Mountain2Icon
                                        className={`${request.location === l.link && classes.active} ${classes.svgicon}`}/>}
                                    <Text type="subtitle3">{l.name}</Text>
                                </Papir>
                            </Grid>))}

                    </Grid>
                </Grid>
            </Grid>
        </animated.div>
    )
}
