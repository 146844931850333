import React, { useState, useEffect } from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { Text } from '../../components/ui-kit/Typo'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

import PkgDetails from './PkgDetails';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    },
    btn: {
      margin: 'auto 0',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end'
      },
    }
}));

export default function PkgType({ places, packages, type }) {

    const { t } = useTranslation('listing');
    const classes = useStyles();

    const findPlace = placeId => {
      return places.find(p => {return p.id === placeId})
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text type="subtitle2" className="sb-red1" bold>
            <span style={{cursor: 'pointer'}}>{t(type)}</span>
          </Text>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {packages.filter((obj) => {
              return obj.pkgType === type;
            }).map((pkg, key) => {
              if (!pkg.active)
                return (null);
              return (
                <PkgDetails
                  place={findPlace(pkg.placeId)}
                  pkg={pkg} />
            )})}
          </Grid>
        </Grid>
      </Grid>
    )
}
