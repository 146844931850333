import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../components/ui-kit/Typo'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ValidateRequestPage(props){

  let { placeLink, token } = useParams();
  const [place, setPlace] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_BUDDY_SERVICE + `/place/${placeLink}/package/validate/${token}`)
      .then(response => setPlace(response.data),
        err => {
          setPlace(err.response.data)
        });
  }, [token])

  return (
    <Grid container spacing={3} justify="space-around" alignItems="center">

      <Grid item md={4}>
        <Text type="title2">Validare Rezervare</Text>
      </Grid>

      <Grid item md={8}>
        <Grid container spacing={3} justify="space-around" alignItems="center">

          {!place ?
            <Grid item xs={12}>
              <CircularProgress color="secondary" />
            </Grid> :
            <Grid item xs={12}>
            {place.responseCode == 400 ?
              <div>
                <Text type="subtitle2"><Text type="span" color="primary" semibold>Ups!</Text> Ceva nu a mers bine</Text>
                <Text type="body">Cererea ta nu a fost procesata cu succes.</Text>
              </div> :
              <div>
                <Text type="subtitle2"><Text type="span" color="secondary" semibold>Super!</Text> Rezervarea este validata.</Text>
                <Text type="body">Dupa atribuirea monitorului vei primi un email de confirmare cu toate detaliile.</Text>
                <Text type="subtitle3">Daca ma razgandesc sau doresc o modificare?</Text>
                <Text type="body">Te rugam sa ne notifici cu cel putin 3 zile inaintea programarii
                  <ul>
                  	<li><a href={`https://snowbuddy.app/ro/iframe/event/edit/${place.link}`} target="_blank">online</a>, prin editarea rezervarii</li>
                  	<li>la numarul de telefon <a href={`tel:${place.placeInfo.phone}`}>{place.placeInfo.phone}</a></li>
                  	<li>print email la <a href={`mailto:${place.placeInfo.email}`}>{place.placeInfo.email}</a></li>
                  </ul>
                </Text>
              </div>
            }
            </Grid>
          }

          <Grid item xs={12}>
            <Text type="subtitle3">#keeptrying</Text>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}
