import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { Text } from '../../../components/ui-kit/Typo'
import { makeStyles } from '@material-ui/core/styles'

import CampBookPage from './CampBookPage'
import { NavLink, useParams } from 'react-router-dom'
import JsonSchema from '../../../components/general/JsonSchema'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    },
    btn: {
      margin: 'auto 0',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end'
      },
    }
}));

export default function PkgCourse({ place }) {

    const { t } = useTranslation('listing');
    let { lang, packageLink } = useParams();
    const classes = useStyles();
    const [selectedPackage, setSelectedPackage] = useState(false);

    return (
      <Grid container spacing={1}>
        <JsonSchema packages={place.placePakage.filter((obj) => {return obj.pkgType === 'COURSE';})} place={place} lang={lang} />
        <Grid item xs={12}>
          <Text type="subtitle2" className="sb-red1" bold>
            <span onClick={() => setSelectedPackage(false)} style={{cursor: 'pointer'}}>{t('COURSE')}</span>
          </Text>
        </Grid>
        <Grid item xs={12}>
          {selectedPackage ?
            <div>
              <p onClick={() => setSelectedPackage(false)} style={{cursor: 'pointer'}}>{'< Inapoi'}</p>
              <CampBookPage pakage={selectedPackage} place={place} setSelectedPackage={setSelectedPackage} />
            </div>:
            <Grid container spacing={3}>
              {place.placePakage.filter((obj) => {
                return obj.pkgType === 'COURSE';
              }).map((pkg, key) => {
                return (
                  <Grid item xs={12}>
                    <Grid container className={classes.root} spacing={3}>
                      <Grid item xs={12} md={10} key={key}>
                        <Grid container  spacing={0}>
                          <Grid item xs={10} md={10}>
                            <NavLink to={location => `/${lang}/${place.link}/${pkg.link}`} title={pkg.title}>
                                <Text type="subtitle3" component="h3" semibold className="sb-blue2">
                                    {pkg.title}
                                </Text>
                              </NavLink>
                          </Grid>
                          <Grid item xs={12}>
                            <Text type="body1" component="h4" className="sb-text body1">
                              {pkg.type.length == 2 ? t('profile.pakage.course_both') : pkg.type.indexOf('snowboard') > 0 ? t('profile.pakage.course_snow') : t('profile.pakage.course_ski')}
                              {pkg.spots} {pkg.spots == 1 ? t('profile.pakage.person') : t('profile.pakage.persons')}
                              {pkg.availability} {pkg.availability == 1 ? t('profile.pakage.lesson') : t('profile.pakage.lessons')}
                              {pkg.timeUnit} {pkg.timeUnit == 1 ? t('profile.pakage.hour') : t('profile.pakage.hours')}
                              <br/>
                                {(pkg.price > 0 &&
                                    <>
                                        {t('profile.pakage.price')} <span className="sb-text regular">{pkg.price} {place.placeInfo.currency}{pkg.pkgPrice ? t('profile.pakage.price_pkg') : t('profile.pakage.price_pers')}</span>
                                    </>
                                )}
                            </Text>
                          </Grid>
                          <Grid item md={12}>
                              <Text type="body" component="p" >{pkg.abs && `"`}{pkg.abs}</Text>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={'auto'} md={2} className={classes.btn}>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                          <Grid>
                            <NavLink to={location => `/${lang}/${place.link}/${pkg.link}`}>
                              <Button variant="outlined" color="primary">
                              {t('profile.pakage.details')}
                              </Button>
                            </NavLink>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
              )})}
            </Grid>
          }
        </Grid>
      </Grid>
    )
}

// {pkg.info && <div dangerouslySetInnerHTML={{__html: convertToHTML(convertFromRaw(JSON.parse(pkg.info)))}} />}
