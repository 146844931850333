import React from 'react'

import { useTranslation } from 'react-i18next';

export default function TermsPage(){

  const { t } = useTranslation(['terms']);

  return (
    <div class="bg">
      <div class="container">
        <div class="welcome">
          <h2 class="title">
            {t('title')}
          </h2>
        </div>
        <div class="main">
          <section>
            <p>{t('p1')}</p>
            <p>{t('p2')}</p>
            <h3 class="subtitle">
              {t('s1')}
            </h3>
            <p>{t('p3')}</p>
            <h3 class="subtitle">
              {t('s2')}
            </h3>
            <p>{t('p4')}</p>
            <h3 class="subtitle">
              {t('s3')}
            </h3>
            <p>{t('p5')}</p>
            <h3 class="subtitle">
              {t('s4')}
            </h3>
            <p>{t('p6')}</p>
            <ul>
              <li>{t('li1')}</li>
              <li>{t('li2')}</li>
              <li>{t('li3')}</li>
              <li>{t('li4')}</li>
              <li>{t('li5')}</li>
              <li>{t('li6')}</li>
              <li>{t('li7')}</li>
            </ul>
            <h3 class="subtitle">
              {t('s5')}
            </h3>
            <p>{t('p7')}</p>
            <h3 class="subtitle">
              {t('s6')}
            </h3>
            <p>{t('p8')}</p>
            <p>{t('p9')}</p>
            <ul>
              <li>{t('li8')}</li>
              <li>{t('li9')}</li>
              <li>{t('li10')}</li>
              <li>{t('li11')}</li>
              <li>{t('li12')}</li>
              <li>{t('li13')}</li>
              <li>{t('li14')}</li>
              <li>{t('li15')}</li>
              <li>{t('li16')}</li>
              <li>{t('li17')}</li>
              <li>{t('li18')}</li>
              <li>{t('li19')}</li>
            </ul>
            <p>{t('p10')}</p>
            <h3 class="subtitle">
              {t('s7')}
            </h3>
            <p>{t('p11')}</p>
            <h3 class="subtitle">
              {t('s8')}
            </h3>
            <p>{t('p12')}</p>
            <h3 class="subtitle">
              {t('s9')}
            </h3>
            <p>{t('p13')}</p>
            <h3 class="subtitle">
              {t('s10')}
            </h3>
            <p>{t('p14')}</p>
            <h3 class="subtitle">
              {t('s11')}
            </h3>
            <p>{t('p15')}</p>
            <h3 class="subtitle">
              {t('s12')}
            </h3>
            <p>{t('p16')}</p>
            <h3 class="subtitle">
              {t('s13')}
            </h3>
            <p>{t('p17')}</p>
            <ul>
              <li>{t('li20')}</li>
              <li>{t('li21')}</li>
              <li>{t('li22')}</li>
            </ul>
            <h3 class="subtitle">
              {t('s14')}
            </h3>
            <p>{t('p18')}</p>
            <br/><br/>
          </section>
        </div>
      </div>
    </div>
    );
}
