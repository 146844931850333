import React from 'react'
import Grid from '@material-ui/core/Grid';
import { Text } from '../../../components/ui-kit/Typo'
import { useTranslation } from 'react-i18next'

export default function StepError(){

  const { t } = useTranslation('iframe');

  return (
    <Grid container spacing={3} justify="space-around" alignItems="center">

      <Grid item md={4}>
        <Text type="subtitle" uppercase>{t('event.book.step_error.online_reservation')}</Text>
      </Grid>

      <Grid item md={8} xs={12}>
        <Grid container spacing={3} justify="space-around" alignItems="center">

            <Grid item xs={12}>
              <Text type="subtitle2"><Text color="primary" semibold type="span">Ups!</Text> {t('event.book.step_error.something_went_wrong')}</Text>
              <Text type="body1">{t('event.book.step_error.err_msg')}</Text>
            </Grid>

          <Grid item xs={12}>
            <Text type="subtitle3">{t('event.book.step_error.hashtag')}</Text>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}
