import React from 'react'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {Text} from '../../../components/ui-kit/Typo'
import 'date-fns';
import {useTranslation} from 'react-i18next';

export default function SubmitSuccess(props) {

    let {setSuccess} = props;
    const {t} = useTranslation('iframe')

    return (
        <Grid container spacing={3} justify="space-around" alignItems="center">

            <Grid item md={4}>
                <Text type="subtitle" uppercase>{t('event.book.step9.reservation_completed')}</Text>
            </Grid>

            <Grid item md={8} xs={12}>

                <Grid container spacing={3} justify="space-around" alignItems="center">

                    <Grid item xs={12}>
                        <Text type="subtitle2"><Text type="span" color="secondary"
                                                     semibold>{t('event.book.step9.awesome')}</Text> {t('event.book.step9.registered_reservation')}
                        </Text>
                        <Text type="body">{t('event.book.step9.email')}</Text>
                        <Text>{t('event.book.step9.ps')}</Text>
                    </Grid>

                    <Grid item xs={12}>
                        <Text type="subtitle3">{t('event.book.step9.hashtag')}</Text>
                    </Grid>

                    <Grid item xs={12} className={"text-center"}>
                        <Button variant="outlined" color="secondary" onClick={() => setSuccess(false)}>
                            {t('camp.book.new')}
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}
