import React from 'react';
import { Text } from '../ui-kit/Typo'
import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import endOfLine from '../../assets/img/debarcare.jpeg'

const Page404 = () => (
  <div class="bg">
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} className="err-div">
        <Text type="title">404</Text>
        <Text type="subtitle2"><Text type="span" color="primary">Ups!</Text> You're lost.</Text>
        <Text type="subtitle3">The page you are looking for does not exist. Click the button below to go back to the homepage.</Text>
        <Text></Text>
        <NavLink to={location => `/${location.pathname.split('/')[1]}`}>
          <Text type="subtitle3">#getabuddy</Text>
        </NavLink>
      </Grid>
      <Grid item xs={12} md={6}>
        <NavLink to={location => `/${location.pathname.split('/')[1]}`}>
          <img src={endOfLine} alt="End of line" style={{maxWidth: '100%', borderRadius: '15px'}} />
        </NavLink>
      </Grid>
    </Grid>

  </div>
)
export default Page404
