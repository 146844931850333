export const themeObject = prefersDarkMode => {
  return {
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: '#EE6E72',
        light: '#FAA094',
        dark: '#FC766A',
        // contrastText: '#fff'
      },
      secondary: {
        main: '#42D4C3',
        light: '#ADEFD1',
        dark: '#5BD9AF',
        // contrastText: '#fff'
      },
      error: {
        main: '#EE6E72',
      },
      warning: {
        main: '#FFBE7B'
      },
      info: {
        main: '#669DB3'
      },
      success: {
        main: '#42D4C3'
      }
    },
    typography: {
      fontFamily: [
        'Raleway',
        'sans-serif',
        // '-apple-system',
        // 'BlinkMacSystemFont',
        // '"Segoe UI"',
        // 'Roboto',
        // '"Helvetica Neue"',
        // 'Arial',
        // 'sans-serif',
        // '"Apple Color Emoji"',
        // '"Segoe UI Emoji"',
        // '"Segoe UI Symbol"',
      ].join(','),
    },
  }
}
